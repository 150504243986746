/**
 *          LIBRARIES
 */
 import React, { useEffect, useState } from "react";
 // import { connect } from "react-redux";
 
 /**
  *          APP COMPONENTS
  */
 import CarsouelPhone from "./carsouel-phone";
 
 /**
  *          JS FUNCTIONS & CONSTANTS
  */
 import 'pure-react-carousel/dist/react-carousel.es.css';
import { getMyInfo } from "../js/get";
import {dark } from "../js/util";

 export default ({ theme }) => {
     const [resumeLink, setResumeLink] = useState('');
     useEffect(() => {

        const getInfo = async () => {
            try{
                const res = await getMyInfo()
                setResumeLink(res.resume)
            }catch(e){
                // console.log("[ERR]@[RESUME]: ", e);
            }
            
        }
        getInfo()
        
     }, [])
 
     return(
        <div className={`full-h `} style={{marginTop: '5rem'}}>
            <div className="col">
                 <div className="col">
                     <div style={{height: '40rem'}} className={`padding-xx full-w col center-hv`}>
                        <a className={`white-link-xx`} href={resumeLink} target="_blank" style={{color: theme === 'light' && dark}} >Download My Resume</a>
                        <iframe className="margin-t-xx" style={{height: '30rem'}} src={resumeLink} />
                     </div>
                 </div>
             </div>
        </div>
     );
 }