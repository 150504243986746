import React from 'react'
import './desktop.css';
import { FaGithubAlt, FaInstagram } from 'react-icons/fa';
import {TiSocialLinkedin} from 'react-icons/ti'
// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
function MainDesktop () {
  return(
    <div className="col center-hv full-w fit-content-h padding-xx">
        <div className="full-wh col center-vh" id="col-container">
            <div className="row fit-content-h full-w title">Contact Info</div>
            <div className="row fit-content-h full-w padding-r-xx padding-l-xx label">You can reach on any of the below social platforms <b>OR</b> you can email me dirctly through the below form!<br/><b>OR</b> Email me on my business email mohammed.a.albasha@elbashah.me<br/><b>OR</b> Email me on my personal email mohammed.a.albasha@gmail.com</div>
            <div className="row full-w fit-content-h padding-xx center-vh j-evenly">
                <div className="center-vh padding-x ">
                    <div className="full-wh radius-xxx radius-t-r-x center-vh" style={{backgroundColor:'#00000050'}}>
                        <TiSocialLinkedin />
                    </div> 
                </div>
                <div className="center-vh padding-x ">
                    <div className="full-wh radius-xxx radius-t-r-x center-vh" style={{backgroundColor:'#00000050'}}>
                        <FaGithubAlt />
                    </div> 
                </div>
                <div className="center-vh padding-x ">
                    <div className="full-wh radius-xxx radius-t-r-x center-vh" style={{backgroundColor:'#00000050'}}>
                        <FaInstagram />
                    </div> 
                </div>
            </div>
        </div>
    </div>
  );
}

export default MainDesktop
