import React from 'react';
// import '../App.css';
import './desktop.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import { FaQuoteLeft, FaQuoteRight, } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FlatList from 'flatlist-react';
import { dark, light, main, white } from '../js/util';
import { LoadingRecs } from '../phone/carsouel-phone';


function AboutDesktop({setRecVisible, testimonials, theme}) {
    const handleOnDragStart = (e) => e.preventDefault()
    const theme_color = 
        theme === 'light'
        ? 'level-fill-light'
        : (
            theme === 'dark'
            ? 'level-fill-dark'
            : ''
        )
    

    return (
        <div className="container-d" id="about-container" style={{ minWidth: '70rem' }} >
            <div className="head-d" style={{ height: 'fit-content' }}>
                <div className="about-content-container">
                    <div className="about-content">
                        <div className="about-name">Mohammed Albasha</div>
                        <div className="about-title" style={{color: theme === 'light'? dark : ''}}>Lead Software Engineer @ pass-me.com</div>
                        <div className="about" style={{color: theme === 'light'? dark : '', fontWeight: 400}}>
                        I'm a Full-Stack Software Engineer, mainly developing scalable web & mobile applications. I'm currently pursuing my master’s degree in computer science and focusing on AI technologies in image processing, I will graduate in Fall 22. I'm currently looking for internships for Summer 22 and a Full-time position for Fall 22. 
        
                        </div>
                      
                    </div>
                    <div className="about-img" >
                        <img src={require('../images/side_pic.jpg')} alt={'non'} style={{
                            width: '14rem',
                            height: '20rem', border: `0.15rem ${theme === 'light'? dark : (theme === 'dark'? main : 'rgb(238, 0, 118)') } solid`, marginTop: '2rem',
                            borderTopLeftRadius: '3rem',
                            borderBottomLeftRadius: '1rem',
                            borderBottomRightRadius: '1rem',
                            opacity: '0.9'
                        }} />
                    </div>
                </div>
            </div>

            <div className="body-d" style={{ height: 'fit-content' }}>
                <div className="testomonials-holder">
                    <div className="testomonials-slider">
                        <Carousel autoPlay style={{}} className="slider-root"
                            showStatus={false}
                            showArrows={false}
                            showThumbs={false}
                            stopOnHover={true}
                            showIndicators={true}
                            swipeable={true}
                            interval={6000}
                            infiniteLoop={true}
                            transitionTime={2000}>
                            {/* <FlatList
                                list={testimonials}
                                renderItem={testimonial => { */}
                                {(testimonials && testimonials.length > 0)
                                ? (testimonials.map(testimonial => {
                                    return(
                                        <div key={window.performance.now()} className="testomonial-holder" onDragStart={handleOnDragStart}>
                                            <div className="testomonial" style={{ background: theme === 'dark'? dark : (theme === 'light'? light : '') }}>
                                                <div className="testomonial-header">
                                                    <div className="bash-image" style={{ justifyContent: 'center', display: 'flex' }}>
                                                        <img src={testimonial.img} alt={'non'} style={{
                                                            width: '6rem', marginLeft: '2rem',
                                                            height: '6rem', borderRadius: '100rem', border: `0.15rem ${theme === 'light'? dark : (theme === 'dark'? main : 'rgb(238, 0, 118)') } solid`, marginTop: '2rem',
                                                        }} />
                                                    </div>

                                                    <div className="testomonee-info">
                                                        <div className="testomonee-name">{testimonial.name}</div>
                                                    <div className="testomonee-job" style={{color: theme === 'light'? dark : ''}}>{testimonial.position} ({testimonial.org})</div>
                                                    </div>
                                                </div>
                                                <div className="testomonial-body" style={{ padding: '3rem' }}>
                                                    <div className="quote" style={{ display: 'flex', justifyContent: 'flex-start', color: '#ee7752' }}>
                                                        <FaQuoteLeft />
                                                    </div>
                                                    <div className="testomonial-body-text" style={{color: theme === 'light'? dark : ''}}>{testimonial.testimonial}</div>
                                                    <div className="quote" style={{ display: 'flex', justifyContent: 'flex-end', color: '#ee7752' }}>
                                                        <FaQuoteRight />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}))
                                    
                                    :(<div className={`row full-w center-hv`}><LoadingRecs theme={theme} /></div>)
                                    }
                                                             
                        </Carousel>
                    </div>
                    <div className="testomonials-add" onClick={() => setRecVisible(true)}>
                        <div className="add-holder" style={{backgroundColor: theme === 'dark'? main : (theme === 'light'? dark : '')}}>
                            <div className="add-sign" style={{ color: theme !== 'main'? white : ''}}>+ &nbsp;</div>
                            <div className="add">ADD YOUR TESTIMONIAL</div>
                        </div>
                    </div> 
                </div>
            </div>

            <div className="footer-d" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'fit-content' }}>
                <div style={{ padding: '2rem', width: '100%', height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', alignItems: 'center' }}>

                    <div style={{ color: '#ee7752', fontSize: '3rem', fontWeight: '500', width: '100%', paddingLeft: '2rem', height:'3rem', marginTop:'3rem' }}>My Technology Stack</div>
                    <div className="col" id="skills" style={{ background: theme !== 'main' && (theme === 'light'? light : dark) }}>

                        <div className="row j-between full-w margin-t-x">
                            <div className="title" style={{color: theme === 'light'? dark : ''}}>Technology Name</div>
                            <div className="title" style={{width:'23vw', color: theme === 'light'? dark : ''}}>Experty Level</div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                                <img src={require('../images/react.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; React <b>JS</b> & React <b>Native</b></div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '95%' }}>Expert</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div> 

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                                <img src={require('../images/js.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Java<b>Script</b></div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '91%' }}>Expert</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>                
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/html.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; HTML</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '88%' }}>Professional</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/css.png')} alt={'non'} className="tech-icon" style={{ width: '3.3rem', height: '3.3rem' }} />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; CSS</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '88%' }}>Professional</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                                <img src={require('../images/node.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Node <b>JS</b></div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '86%' }}>Expert</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/fb.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Firebase</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '84%' }}>Professional</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/python.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Python / Flask</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '84%' }}>Very Good</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>
                        
                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/android.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Android</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '80%' }}>Very Good</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/swift.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Swift</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '73%' }}>Good</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/aws.png')} alt={'non'} className="tech-icon" style={{ borderRadius: '0.3rem', width: '2.7rem', height: '2.7rem' }} />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; AWS</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '60%' }}>Average</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/serverless.png')} alt={'non'} className="tech-icon" style={{ borderRadius: '0.3rem', width: '2.7rem', height: '2.7rem' }} />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Serverless</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '50%' }}>Average</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                                <img src={require('../images/angular.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Angular <b>JS</b></div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '60%' }}>Average</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/electronjs.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Electron <b>JS</b></div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '55%' }}>Average</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/cordova.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Cordova</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '45%' }}>Average</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>
                        
                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/spring.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Spring-Boot</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '43%' }}>Average</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/tomcat.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Tomcat</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '40%' }}>Average</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/docker.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Docker</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '40%' }}>Average</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>
                        
                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/cpp.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; C++</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '55%' }}>Average</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/cs.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; C#</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '40%' }}>Average</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>


                        <div className="row j-between full-w margin-t-xx">
                            <div className="row">
                            <img src={require('../images/java.png')} alt={'non'} className="tech-icon" />
                                <div className="lable font-size-x white" style={{color: theme === 'light'? dark : ''}}>&nbsp; Java</div>
                            </div>

                            <div className="skill-level-holder radius-xxx row" style={{}}>
                                <div className={`bg-light level-fill ${theme_color}`} style={{ width: '85%' }}>Very Good</div>
                                <div className="bg-transparent" style={{}}></div>
                            </div>
                        </div>

                    </div>
                </div>

                
                <div className="copy-right" style={{color: theme === 'light'? dark : ''}}>Copyrights &#169; belongs to author (
                    <a href="https://github.com/ElBashah" target="_blank" style={{ color: '#ee7752', fontWeight: '800' }}>Elbashah</a>)</div>
            </div>
        </div>
    );
}

export default AboutDesktop;
