let url = "https://us-central1-bashbio.cloudfunctions.net/app";
if(!window.location.href.includes('elbashah'))
url = "http://192.168.1.27:5051/bashbio/us-central1/app";

export const getTestimonials = () => GET('getTestimonials', '');
export const getProjects = () => GET('getProjects', '');
export const getMyInfo = () => GET('getMyInfo', '');

export const GET = (endpoint, params) => {
    return fetch(url + `/${endpoint}${params}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
    .then(resp => resp.json())
    .then(obj => obj)
    .catch(error => console.log("\n[ERR]  @  [GET]  @  []\n[ERROR::MSG]:\n", 'error'))
}