// import * as firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";
// import 'firebase/storage';
// // import firebaseConfig from "../../src/firebaseConfig";

// // initalize firebase 
// firebase.initializeApp(firebaseConfig);

// // var firebase = {};
// export default firebase;


import firebase from 'firebase';
// import { useContext } from 'react';
// import * as auth from 'firebase'

// working package :      "firebase": "7.20.0",
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAEK-N6cM8wReOQmFmHx18kE4GkCRaxhnU",
    authDomain: "bashbio.firebaseapp.com",
    databaseURL: "https://bashbio.firebaseio.com",
    projectId: "bashbio",
    storageBucket: "bashbio.appspot.com",
    messagingSenderId: "102885885586",
    appId: "1:102885885586:web:e586d6cff4fd820043484b",
    measurementId: "G-KS0V97FG65"
}

export const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth()
// export const signAnonymousUser = auth.signInAnonymously()
// export const useAuthState = () => {
//   const _auth = useContext(auth)
//   console.log("Auth cont: ", _auth);
//   return { ..._auth, isAuthenticated: _auth.currentUser != null }
// }

