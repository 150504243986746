import React, {useState } from 'react';
// import '../App.css';
import './desktop.css';
import { dark, getDeviceUUID, isEmpty, white } from '../js/util';
import { postTestimonial, postTestimonialImage } from '../js/post';
import { GoPerson } from 'react-icons/go';
import { FaWindowClose } from 'react-icons/fa';
import { TextField } from '@material-ui/core';
import { AlertBox } from '../phone/recommendation-form-phone';

function Recommendation({setRecVisible, theme}) {
    const [name, setName] = useState('');
    const [position, setPosition] = useState('');
    const [org, setOrg] = useState('');
    const [testimonial, setTestimonial] = useState('');
    const [img, setImg] = useState({pictures: []});
    const [showAlert, setShowAlert] = useState({visible: false, msg: '', success: false, loading: false});
    const [requiredField, setRequiredField] = useState({
        name: false,
        org: false,
        position: false,
        testimonial: false
    });

    let defaultImageName = 'b-rec.png';
    let imgURL = '';
    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePositionChange = (event) => {
        setPosition(event.target.value);
    };

    const handleOrgChange = (event) => {
        setOrg(event.target.value);
    };

    const handleTestimonialChange = (event) => {
        setTestimonial(event.target.value);
    };

    const hiddenFileInput = React.useRef(null);
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

      const handleImgChange = event => {
        const fileUploaded = event.target.files[0];

        defaultImageName = fileUploaded.name;
        imgURL = URL.createObjectURL(fileUploaded);
        // setImg({pictures: [{name: fileUploaded.name}]})
        const reader = new FileReader();
        reader.onload = handleReaderLoaded;
        reader.readAsBinaryString(fileUploaded);

      };

      const handleReaderLoaded = (e) => {
          let binaryString = e.target.result;
          setImg({pictures: [{file: btoa(binaryString), name: defaultImageName, url: imgURL}]});
      }

    return (
        <div className={`rec-container`} style={{ display: 'flex', }}>
            <div className={`rec`} style={{backgroundColor: theme === 'light' && dark}}>
                <div  className={`row full-w j-end radius-x`} style={{height:'3rem', alignItems:'flex-start', backgroundColor: theme === 'light' && dark }}>
                    <FaWindowClose onClick={() => {setRecVisible(false)}} style={{ width: '3rem', height: '3rem', color: 'var(--main_color)', marginTop:'0.5rem', marginRight:'0.5rem', cursor:'pointer'}} />
                </div>
                <div className={`row full-w j-center`} style={{height:'8rem'}}>  
                    <div className={`j-start row textfield-phone-container`} style={{width:'71%', color: theme === 'light' && white, height: '5rem'}}>
                        <TextField className={`textfield-phone txt`} value={name} onChange={handleNameChange} label={`${requiredField.name? 'Please Enter Your Name' : 'Name'}`} color={theme === 'light'? white : dark} id="txt-name" autoFocus={true} required style={{ width: '100%', color: theme === 'light' && white }} inputProps={{}} />
                    </div>

                    <div className={`col a-end`} style={{width:'15%'}}>
                        {img.pictures.length > 0
                            ? (
                                <div className={`rec-img-holder`}>
                                    <img src={img.pictures[0].url} alt="" className={`img`}/>
                                </div>
                            )
                            : (
                                <div onClick={() => handleClick()}>
                                    <GoPerson className={`person-pic`} onClick={() => handleClick}/>
                                    <input type="file" style={{display:'none'}} ref={hiddenFileInput} accept=".jpeg, .png, .jpg"
                                        onChange={handleImgChange}/> 
                                    <div className={`add-img`} style={{color: theme === 'light' && white}} ><div className={`main-color`}><b>+</b></div>&nbsp; Image</div>
                                </div>
                            )
                        }
                        
                    </div>

                    {/* <input type='file' className={`file-picker`} onChange={handleImageChange}/> */}
                </div>

                <div className={`row full-w j-evenly`} style={{height:'5rem', alignItems:'flex-start', }}>
                    <div className={`j-start row textfield-phone-container`} style={{width:'40%'}}>
                        <TextField value={position} onChange={handlePositionChange} label={`${requiredField.position? 'Please Enter Your Current Position' : 'Current Positin'}`} id="txt-position" className={`txt textfield-phone`} required style={{ width: '100%', color: theme === 'light' && white}} inputProps={{}} />
                    </div>
                    <div className={`j-start row textfield-phone-container`} style={{width:'40%'}}>
                        <TextField value={org} onChange={handleOrgChange} label={`${requiredField.org? 'Please Enter Your Company' : "Organization / Company"}`} id="txt-org" className={`txt textfield-phone`} required style={{ width: '100%', color: theme === 'light' && white}} inputProps={{}} />
                    </div>
                </div>

                <div className={`row full-w j-center`} style={{height:'17rem' }}>
                    <div className={`j-start row textfield-phone-container`} style={{width:'88%', padding: '1rem'}}>
                        <TextField
                            id="outlined-multiline-static"
                            label={`${requiredField.testimonial? 'You must write your RECOMMENDATION or TESTIMONIAL before submitting!' : "Testimonial / Recommendation"}`}
                            multiline
                            // className={'textfield-phone txt'}
                            rows={10}
                            value={testimonial} 
                            onChange={handleTestimonialChange}
                            style={{width:'100%', backgroundColor:'transparent', color: theme === 'light' && white, }}
                            variant="outlined"
                        />
                    </div>
                </div>

                <div className={`row full-w j-center margin-t-x`} style={{height:'5rem' }}>
                    <div onClick={() => submit(name, position, org, img, testimonial, setRequiredField, setShowAlert, showAlert, setRecVisible)} className={`bg-main padding-x white center-hv `} style={{width:'15rem', height:'4rem', borderRadius:'0.5rem', fontSize:'2rem', cursor:'pointer', color: theme === 'light' && white}}>Submit</div>
                </div>

            </div>

            {showAlert.visible && <AlertBox setRecVisible={setRecVisible} success={showAlert.success} msg={showAlert.msg} setShowAlert={setShowAlert} showAlert={showAlert} theme={theme} loading={showAlert.loading} setRecVisible={setRecVisible} />}
        </div>
    );
}

async function submit(name, position, org, img, testimonial, setRequiredField, setShowAlert, showAlert, setRecVisible ){

    if(isEmpty(name) || isEmpty(position) || isEmpty(org) || isEmpty(testimonial)){
        setRequiredField({
            name: isEmpty(name),
            org: isEmpty(org),
            position: isEmpty(position),
            testimonial: isEmpty(testimonial)
        })
        setShowAlert({...showAlert, msg: 'Please fill required fields!', visible: true, success: false, loading: false})
        return;
    }


    setShowAlert({...showAlert, visible: true, loading: true})

    const uuid = getDeviceUUID();
    const web_engine = navigator.userAgent;
    const platform_type = navigator.platform;
    const browser = `${navigator.appCodeName}-${navigator.vendor}`;
    const browser_language = window.navigator.language;
    const location = window.navigator.geolocation || {};

    const res = await postTestimonial({testimonial:{ name, position, org, testimonial, uuid,
        web_engine,
        platform_type,
        browser,
        browser_language,
        location}})

    if(res && res.response_code === 2000){
        if(img.pictures[0]){
            const res2 = await postTestimonialImage({tid: res.tid, testimonial:{
                img: img.pictures[0]? img.pictures[0] : null, 
                name, 
                position,
                org,
                testimonial,
                uuid,
                web_engine,
                platform_type,
                browser,
                browser_language,
                location
             }})

            
            if(res2 && res2.response_code === 2000){
                setShowAlert({...showAlert, msg: 'Thanks for your recommendation!', visible: true, success: true, loading: false})
            } else setShowAlert({...showAlert, msg: 'Your recommendation recorded, but the image failed! Try a lower size image!', visible: true, success: false, loading: false})
        } else setShowAlert({...showAlert, msg: 'Thanks for your recommendation!', visible: true, success: true, loading: false})
    }
    else setShowAlert({...showAlert, msg: 'Something went wrong!', visible: true, success: false, loading: false})
    
}

export default Recommendation;