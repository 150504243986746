/**
 *          LIBRARIES
 */
import React, { useState } from "react";
// import { connect } from "react-redux";
import { ImMenu } from 'react-icons/im';
// import {Collapse} from 'react-collapse';
import {Link} from "react-router-dom";
import { GoPerson } from 'react-icons/go';
import { BsFileEarmarkText } from 'react-icons/bs';
import { FaCode, FaGithubAlt, FaInstagram, FaRobot, FaPhoneAlt } from 'react-icons/fa';
import { MdEmail, } from 'react-icons/md';
import {TiSocialLinkedin} from 'react-icons/ti'
/**
 *          APP COMPONENTS
 */
import BashPic from "../../images/portfolio_pic.jpg"

/**
 *          JS FUNCTIONS & CONSTANTS
 */ 
import { APP } from "../../js/app-constants";
import { VIEWS } from "../../navigation/views-stack";
import { background, dark, white, main, dimDark } from "../../js/util";

export default ({setViewStack, view, theme, setTheme}) => {

    const [opened, setOpened] = useState(false);
    const background = theme === 'main'? '--background' : (theme === 'dark'? '--dark' : '--light')
    
    
    return(
            <div className={`phone-header-container col a-center`} style={{height: opened &&  '1000px', backgroundColor: `var(${background})`}}>
                <div className={`row full-w padding-x j-start`}>
                    <div className={`phone-header-menu`} style={{width: '25%'}} onClick={() => setOpened(!opened)}>
                        <ImMenu className={`icon-white-xxx`} style={{ color: theme === 'light'? dark : '' }} />
                    </div>
                    <div className={`font-title-m row j-start a-center `} style={{width: '75%', color: theme === 'light'? dark : white}}>Mohammed Albasha</div>
                </div>

                {opened && (
                    <div className={`col a-center full-wh j-start`}>
                        <div className={`row center-hv`}>
                            <img src={BashPic} alt={'non'} style={{
                                width: '10rem',
                                height: '10rem', borderRadius: '100rem', border: `0.15rem ${theme === 'light'? dark : (theme === 'dark'? main : 'rgb(238, 0, 118)') } solid`, marginTop: '2rem',
                            }} />
                        </div>

                        <div className={`full-w margin-t-x font-lable-m padding-l-x padding-r-x t-center`}
                            style={{lineHeight: 1.3, height: '3rem', fontWeight: '300', fontWeight: 400, color: theme === 'light'? dark : white}}>
                            Hi, this is Mohammed Albasha, I'm the Founder & Lead Development Engineer @ <a target="_blank" href="https://www.pass-me.com">Pass-Me.com</a> 
                        </div>

                        <div className={`full-w`} style={{display:'flex', justifyContent:'center'}}>
                            <div className="themes-holder" style={{width: '60%'}}>
                                <div className="social-icon" style={{cursor:'pointer', color: theme !== 'main'? white : ''}} onClick={() => window.open(APP.linkedin, "_blank")}>
                                    <TiSocialLinkedin />
                                </div>
                                <div className="social-icon" style={{cursor:'pointer', color: theme !== 'main'? white : ''}} onClick={() => window.open(APP.github, "_blank")}>
                                    <FaGithubAlt />
                                </div>
                                <div className="social-icon" style={{cursor:'pointer', color: theme !== 'main'? white : ''}} onClick={() => window.open(APP.instagram, "_blank")}>
                                    <FaInstagram />
                                </div>
                            </div>
                        </div>
                        
                        <br/>
                        <div style={{ display: 'flex', justifyContent: 'center', width:'100%' }}>
                            <div className="rounded-seperator" style={{width: '80%', }}></div>
                        </div>
                        <br/>

                        <div className="dir-links-holds" style={{ padding: '1rem', width: '100%' }}>
                            <div className="dir-links">
                                <div className={`dir-link-${theme === 'dark'? 'dark' : (theme === 'main'? 'main' : 'light') }`} onClick={() => view !== VIEWS.ABOUT ?  setViewStack({view:VIEWS.ABOUT}, setOpened(false)) : setViewStack({view:VIEWS.ABOUT}, setOpened(false))}>
                                    <div className="link-icon">
                                        <GoPerson />
                                    </div>
                                    <div className={`link-text ${view === VIEWS.ABOUT && 'main-color'}`} style={{color: theme === 'light'? dark : ''}}>About me</div>
                                </div>
                                <div className={`dir-link-${theme === 'dark'? 'dark' : (theme === 'main'? 'main' : 'light') }`} onClick={() =>  view !== VIEWS.PORTFOLIO ? setViewStack({view: VIEWS.PORTFOLIO}, setOpened(false)) : setOpened(false)}>
                                    <div className="link-icon">
                                        <FaCode />
                                    </div>
                                    <div className={`link-text ${view === VIEWS.PORTFOLIO && 'main-color'}`} style={{color: theme === 'light'? dark : ''}}>Portfolio</div>
                                </div>
                                <div className={`dir-link-${theme === 'dark'? 'dark' : (theme === 'main'? 'main' : 'light') }`} onClick={() =>  view !== VIEWS.RESUME ? setViewStack({view: VIEWS.RESUME}, setOpened(false)) : setOpened(false)}>
                                    <div className="link-icon">
                                        <BsFileEarmarkText />
                                    </div>
                                    <div className={`link-text ${view === VIEWS.RESUME && 'main-color'}`} style={{color: theme === 'light'? dark : ''}}>Resume</div>
                                </div>
                            
                            </div>
                        </div>

                        <br/>
                        <div style={{ display: 'flex', justifyContent: 'center', width:'100%' }}>
                            <div className="rounded-seperator" style={{width: '80%',}}></div>
                        </div>
                        <br/>

                        <div className={`col full-w`}>
                            
                            <div className={`link-text row center-hv `} style={{color: theme === 'light'? dark : ''}}>Themes</div>

                            <br/>

                            <div className={`row full-w j-around`}>
                                <div className={`row theme-holder`} >
                                    <div onClick={() => setTheme('dark', setOpened(false, localStorage.setItem('theme', 'dark')))} style={{backgroundColor: theme === 'dark'? dimDark : '', width: 'calc(100% / 3)', height: '100%', borderTopLeftRadius: '5rem', borderBottomLeftRadius: '5rem', padding: '0.5rem' }} className="row center-hv"> <div className={``} style={{}}><img src={require('../../assets/moon.png')} alt={'non'} style={{ width: '2.5rem', height: '2.5rem',  }} /></div>  </div> 
                                    <div onClick={() => setTheme('main', setOpened(false, localStorage.setItem('theme', 'main')))} style={{backgroundColor: theme === 'main'? dark : '', width: 'calc(100% / 3)', height: '100%', padding: '0.5rem' }} className="row center-hv "> <div className={`gradiant`} style={{ width: '2.5rem', height: '2.5rem', borderRadius: '5rem' }}>&nbsp;</div>   </div>
                                    <div onClick={() => setTheme('light', setOpened(false, localStorage.setItem('theme', 'light')))} style={{backgroundColor: theme === 'light'? dark : '', width: 'calc(100% / 3)', height: '100%', borderTopRightRadius: '5rem', borderBottomRightRadius: '5rem', padding: '0.5rem' }} className="row center-hv"> <div className={``} style={{}}><img src={require('../../assets/sun.png')} alt={'non'} style={{ width: '2.5rem', height: '2.5rem',  }} /></div>  </div> 
                                </div>
                            </div>
                        </div>

                        <br/>
                        <div style={{ display: 'flex', justifyContent: 'center', width:'100%' }}>
                            <div className="rounded-seperator" style={{width: '80%',}}></div>
                        </div>
                        <br/>

                        <div className="dir-links-holds" style={{ padding: '1rem', width: '100%' }}>
                            <div className="dir-links">
                                <div className={`dir-link-${theme === 'dark'? 'dark' : (theme === 'main'? 'main' : 'light') }`} style={{height: '2.5rem'}} >
                                    <div className="link-icon">
                                        <FaPhoneAlt style={{ width: '1.5rem', height: '1.5rem', color: '#ee7752' }}/>
                                    </div>
                                    <div className={`link-text `} style={{fontSize: '1.15rem', color: theme === 'light'? dark : ''}}>+1-480-401-7023</div>
                                </div>
                                <div className={`dir-link-${theme === 'dark'? 'dark' : (theme === 'main'? 'main' : 'light') }`} style={{height: '2.5rem'}}>
                                    <div className="link-icon">
                                    <MdEmail style={{ width: '1.8rem', height: '1.8rem', color: '#ee7752' }}/>
                                    </div>
                                    <div className={`link-text `} style={{fontSize: '1.15rem', textDecoration: 'underline', color: theme === 'light'? dark : ''}}>mohammed.a.albasha@gmail.com</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                
            </div>
    );
}