/**
 *          LIBRARIES
 */
import React, { useEffect, useState } from "react";
import { dark, white } from "../js/util";
// import { connect } from "react-redux";

/**
 *          APP COMPONENTS
 */

/**
 *          JS FUNCTIONS & CONSTANTS
 */




export default ({theme}) => {
    return (
        <div className={`row full-wh padding-x`}>
            <div className={`row j-around full-w`}>
                <div className={`col a-start`}>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; React <b>JS</b> & React <b>Native</b></div>
                    </div>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; J<b>S</b> & HTML & CSS</div>
                    </div>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; Node<b>JS</b></div>
                    </div>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; Python / Flask</div>
                    </div>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; Java / Android</div>
                    </div>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; Firebase</div>
                    </div>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; Swift</div>
                    </div>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; AWS / Serverless</div>
                    </div>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; Angular<b>JS</b></div>
                    </div>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; Spring<b>Boot</b> / Tomcat</div>
                    </div>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; Docker</div>
                    </div>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; Electron<b>JS</b></div>
                    </div>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; C++</div>
                    </div>
                    <div className={`row j-start`}>
                        <div  style={{fontWeight: 400, color: theme === 'light'? dark : '' }} className="font-lable-m skill-phone">&nbsp; Visual<b>Basic</b></div>
                    </div>
                </div>
                
                <div className={`col a-start `} style={{width: '45%'}}>
                    <div className={`row j-start full-w`}> 
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '100%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>9.5</div>
                    </div>
                    <div className={`row j-start full-w `}>
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '95%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>9.2</div>
                    </div>
                    <div className={`row j-start full-w`}>
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '90%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>9</div>
                    </div>
                    <div className={`row j-start full-w`}>
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '85%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>9</div>
                    </div>
                    <div className={`row j-start full-w`}>
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '80%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>8.8</div>
                    </div>
                    <div className={`row j-start full-w`}>
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '75%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>8</div>
                    </div>
                    <div className={`row j-start full-w`}>
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '70%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>7</div>
                    </div>
                    <div className={`row j-start full-w`}>
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '65%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>6.5</div>
                    </div>
                    <div className={`row j-start full-w`}>
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '60%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>6</div>
                    </div>
                    <div className={`row j-start full-w`}>
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '55%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>5.3</div>
                    </div>
                    <div className={`row j-start full-w`}>
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '50%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>5</div>
                    </div>
                    <div className={`row j-start full-w`}>
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '45%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>5</div>
                    </div>
                    <div className={`row j-start full-w`}>
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '40%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>3.2</div>
                    </div>
                    <div className={`row j-start full-w`}>
                        <div  style={{fontWeight: 400, backgroundColor: theme === 'light'? dark : '' }} className="font-lable-m skill-bar-phone" style={{width: '35%', fontWeight: 400, backgroundColor: theme === 'light'? dark : '', fontWeight: 400, color: theme === 'light'? white : ''}}>3</div>
                    </div>
                </div>
            </div>
        </div>
    );
}