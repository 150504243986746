/**
 *          LIBRARIES
 */
import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
import { postTestimonial, postTestimonialImage } from '../js/post';
import { GoPerson } from 'react-icons/go';
import { BarWave } from "react-cssfx-loading";
import { TextField } from '@material-ui/core';

/**
 *          APP COMPONENTS
 */


/**
 *          JS FUNCTIONS & CONSTANTS
 */

import { extreemDark, isEmpty, light, dark, white, main, getDeviceUUID } from "../js/util";

 export default ({setRecVisible, showRec, theme}) => {
    const [name, setName] = useState('');
    const [showAlert, setShowAlert] = useState({visible: false, msg: '', success: false, loading: false});
    const [position, setPosition] = useState('');
    const [org, setOrg] = useState('');
    const [testimonial, setTestimonial] = useState('');
    const [img, setImg] = useState({pictures: []});
    // const [opened, setOpened] = useState(false);
    const [requiredField, setRequiredField] = useState({
        name: false,
        org: false,
        position: false,
        testimonial: false
    });

    let defaultImageName = 'b-rec.png';
    let imgURL = '';
    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePositionChange = (event) => {
        setPosition(event.target.value);
    };

    const handleOrgChange = (event) => {
        setOrg(event.target.value);
    };

    const handleTestimonialChange = (event) => {
        setTestimonial(event.target.value);
    };

    const hiddenFileInput = React.useRef(null);
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

      const handleImgChange = event => {
        const fileUploaded = event.target.files[0];

        defaultImageName = fileUploaded.name;
        imgURL = URL.createObjectURL(fileUploaded);
        const reader = new FileReader();
        reader.onload = handleReaderLoaded;
        reader.readAsBinaryString(fileUploaded);

      };

      const handleReaderLoaded = (e) => {
          let binaryString = e.target.result;
          setImg({pictures: [{file: btoa(binaryString), name: defaultImageName, url: imgURL}]});
      }

    return (
        <div className={`full-vwh j-start a-center col padding-xx gradiant-new`} style={{zIndex: 1, position: 'absolute', top: 0, height: '1000px', background: theme !== 'main' && (theme === 'light'? light : extreemDark)}}>
            <div className={`col j-start a-center padding-xx v-scroll full-w`}>
                <div className={`row center-hv margin-t-x`}>
                    <div className={`col a-end`} >
                        {img.pictures.length > 0
                            ? (
                                <div className={`rec-img-holder`} style={{width: '10rem', height: '10rem'}}>
                                    <img src={img.pictures[0].url} alt="" className={`img`}/>
                                </div>
                            )
                            : (
                                <div onClick={() => handleClick()}>
                                    <GoPerson className={`person-pic-phone`} style={{ border: (theme === 'light' && `0.1rem solid ${dark}` ), color: (theme === 'light' && dark )}} onClick={() => handleClick}/>
                                    <input type="file" style={{display:'none'}} ref={hiddenFileInput} accept=".jpeg, .png, .jpg"
                                        onChange={handleImgChange}/> 
                                    <div className={`add-img font-lable-x `}><div className={`main-color`}><b>+</b></div>&nbsp;<div style={{color: theme === 'light'? dark : white}}>Image</div></div>
                                </div>
                            )
                        }
                                
                    </div>
                </div>

                <div className={`col j-start a-center margin-t-xx full-w`}>
                    <div className={`textfield-phone-container`}>
                        <TextField 
                            value={name} 
                            onChange={handleNameChange} 
                            label={`${requiredField.name? 'Please Enter Your Name' : 'Name'}`} 
                            id="txt-name" 
                            className={`textfield-phone txt`} 
                            autoFocus={true} 
                            required 
                            style={{ width: '100%', }} 
                            inputProps={{}} 
                        />
                    </div>
                    <div className={`textfield-phone-container`}>
                        <TextField 
                            value={position} 
                            onChange={handlePositionChange} 
                            label={`${requiredField.position? 'Please Enter Your Current Position' : 'Current Positin'}`} 
                            id="txt-position" 
                            required 
                            className={`textfield-phone txt`} 
                            inputProps={{}} 
                            // style={{ backgroundColor: theme === 'light' && light}}
                        />
                    </div>
                    <div className={`textfield-phone-container`}>
                        <TextField 
                            value={org} 
                            onChange={handleOrgChange} 
                            label={`${requiredField.org? 'Please Enter Your Company' : "Organization / Company"}`} 
                            id="txt-org" 
                            required 
                            className={`textfield-phone txt`} 
                            inputProps={{}}
                            // style={{ backgroundColor: theme === 'light' && light}}
                        />
                    </div>
                    <div className={`j-start row full-w margin-t-x `} style={{borderRadius: '0.5rem'}}>
                        <TextField
                            id="outlined-multiline-static"
                            label={`${requiredField.testimonial? 'You must write your RECOMMENDATION or TESTIMONIAL before submitting!' : "Testimonial / Recommendation"}`}
                            multiline
                            rows={10}
                            value={testimonial} 
                            onChange={handleTestimonialChange}
                            style={{width:'100%', backgroundColor:'#f9f9f9', borderRadius: '1rem', }}
                            variant="outlined"
                        />
                    </div>
                </div>

                <div className={`row full-w j-between margin-t-xx padding-l-x padding-r-x`} style={{height: '4rem', }}>
                    <div  onClick={() => { setRecVisible(false)}} className={` row center-hv font-title-m`} style={{color: theme === 'light' ? dark : white}}  >Close</div>
                    <div onClick={() => submit(name, position, org, img, testimonial, setRequiredField, setShowAlert, showAlert)} className={` row center-hv font-title-m`} style={{ cursor:'pointer', color: theme === 'light' ? dark : white, }}>Submit</div>
                </div>
            </div>
            
            {showAlert.visible && <AlertBox setRecVisible={setRecVisible} success={showAlert.success} msg={showAlert.msg} setShowAlert={setShowAlert} showAlert={showAlert} theme={theme} loading={showAlert.loading} setRecVisible={setRecVisible} />}
        </div>
 ); 
}

export const AlertBox = ({success, msg, setShowAlert, showAlert, theme, loading, setRecVisible}) => {

    const success_emojies_list = [
        require('../assets/success_emojies/e1.png'),
        require('../assets/success_emojies/e2.png'),
        require('../assets/success_emojies/e3.png'),
        require('../assets/success_emojies/e4.png'),
        require('../assets/success_emojies/e5.png'),
        require('../assets/success_emojies/e6.png'),
    ]
    const fail_emojies_list = [
        require('../assets/fail_emojies/f1.png'),
        require('../assets/fail_emojies/f2.png'),
        require('../assets/fail_emojies/f3.png'),
        require('../assets/fail_emojies/f4.png'),
        require('../assets/fail_emojies/f5.png'),
        require('../assets/fail_emojies/f6.png'),
    ]

    const selected_icon = success? success_emojies_list[Math.floor(Math.random()*success_emojies_list.length)] : fail_emojies_list[Math.floor(Math.random()*fail_emojies_list.length)]

    return (
        <>
            {loading
            ? (
                <div className="alert-box-container">
                    <div className={`alert-box`} style={{ backgroundColor: theme === 'light' && light}} >
                        <BarWave color={theme === 'light'? dark : white} width="150px" height="45px" />
                        <div className={`font-lable-x t-center center-hv margin-t-xx`} style={{lineHeight: 1.5, fontWeight: 400, marginTop: '4rem', color: theme === 'light'? dark : white}}>
                            Uploading...
                        </div>
                    </div>
                </div>
            )
            : (
                <div className="alert-box-container">
                    <div className={`alert-box`} style={{ backgroundColor: theme === 'light' && light}} >
                        <div className="x-button-container">
                            <div className="x-button clickable-dark" onClick={() => setShowAlert({...showAlert, visible: false}, success && setRecVisible(false))}>X</div>
                        </div>
                        <div> 
                            <img src={selected_icon} alt={'non'} style={{ width: '4rem', height: '4rem',  marginTop: '2rem' }} />
                        </div>
                        <div className={`font-lable-x t-center center-hv margin-t-xx`} style={{lineHeight: 1.5, fontWeight: 400, color: theme === 'light'? dark : white, paddingLeft: '0.4rem', paddingRight: '0.4rem'}}>
                            {msg}
                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
}
async function submit(name, position, org, img, testimonial, setRequiredField, setShowAlert, showAlert ){

    if(isEmpty(name) || isEmpty(position) || isEmpty(org) || isEmpty(testimonial)){
        setRequiredField({
            name: isEmpty(name),
            org: isEmpty(org),
            position: isEmpty(position),
            testimonial: isEmpty(testimonial)
        })
        setShowAlert({...showAlert, msg: 'Please fill required fields!', visible: true, success: false, loading: false})
        return;
    }


    setShowAlert({...showAlert, visible: true, loading: true})

    const uuid = getDeviceUUID();
    const web_engine = navigator.userAgent;
    const platform_type = navigator.platform;
    const browser = `${navigator.appCodeName}-${navigator.vendor}`;
    const browser_language = window.navigator.language;
    const location = window.navigator.geolocation || {};


    const res = await postTestimonial({testimonial:{ name, position, org, testimonial, uuid,
        web_engine,
        platform_type,
        browser,
        browser_language,
        location}})

    if(res && res.response_code === 2000){
        if(img.pictures[0]){
            const res2 = await postTestimonialImage({tid: res.tid, testimonial:{
                img: img.pictures[0]? img.pictures[0] : null, 
                name, 
                position,
                org,
                testimonial,
                uuid,
                web_engine,
                platform_type,
                browser,
                browser_language,
                location
             }})

            if(res2 && res2.response_code === 2000){
                setShowAlert({...showAlert, msg: 'Thanks for your recommendation!', visible: true, success: true, loading: false})
            } else setShowAlert({...showAlert, msg: 'Your recommendation recorded, but the image failed! Try a lower size image!', visible: true, success: false, loading: false})
        } else setShowAlert({...showAlert, msg: 'Thanks for your recommendation!', visible: true, success: true, loading: false})
    }
    else setShowAlert({...showAlert, msg: 'Something went wrong!', visible: true, success: false, loading: false})
    
}