/**
 *          LIBRARIES
 */
import React, { useEffect, useState,  } from "react";
// import { connect } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/core";
import { getProjects } from "../js/get";
import { reArrangeProjectsPhone } from "../js/util";
import { light, dark, white, main, purple } from "../js/util";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
margin-top: calc(50vh - 15rem);

`;

/**
 *          APP COMPONENTS
 */


/**
 *          JS FUNCTIONS & CONSTANTS
 */


export default ({ theme }) => {
    const [projects, setProjects] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedHash, setSelectedHash] = useState('all')

    useEffect(() => { 
        
        const getInfo = async () => {
            const newProjects = await getProjects();
            
            const arrangedProjects = reArrangeProjectsPhone(newProjects)
            setProjects(arrangedProjects,)
                setSelectedProjects(arrangedProjects.all)
                    setSelectedHash('all'); 
        }
        getInfo()
    }, []);

    const getThemeColor = (_theme) => _theme === 'main'? main : dark

    return(
        <div className={` full-w`} style={{marginTop: '5rem'}} id="phone-portfolio">
            <div className="col full-w a-center">
                
                <div className={`row full-w padding-l-x padding-r-x h-scroll margin-t-xx j-start a-center`} style={{height: '5rem'}}>
                    <div onClick={() => setSelectedHash('all', setSelectedProjects(projects.all))} className={`option-header ${selectedHash === 'all' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'all' && getThemeColor(theme), color:selectedHash === 'all' && 'var(--white)'}}>All</div>
                    <div onClick={() => setSelectedHash('web', setSelectedProjects(projects.web))} className={`option-header ${selectedHash === 'web' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'web' && getThemeColor(theme), color:selectedHash === 'web' && 'var(--white)'}}>Web</div>
                    <div onClick={() => setSelectedHash('mobile', setSelectedProjects(projects.mobile))} className={`option-header ${selectedHash === 'mobile' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'mobile' && getThemeColor(theme), color:selectedHash === 'mobile' && 'var(--white)'}}>Mobile</div>
                    <div onClick={() => setSelectedHash('desktop', setSelectedProjects(projects.desktop))} className={`option-header ${selectedHash === 'desktop' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'desktop' && getThemeColor(theme), color:selectedHash === 'desktop' && 'var(--white)'}}>Desktop</div>
                    <div onClick={() => setSelectedHash('frontend', setSelectedProjects(projects.frontend))} className={`option-header ${selectedHash === 'frontend' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'frontend' && getThemeColor(theme), color:selectedHash === 'frontend' && 'var(--white)'}}>Frontend</div>
                    <div onClick={() => setSelectedHash('backend', setSelectedProjects(projects.backend))} className={`option-header ${selectedHash === 'backend' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'backend' && getThemeColor(theme), color:selectedHash === 'backend' && 'var(--white)'}}>Backend</div>
                    <div onClick={() => setSelectedHash('fullstack', setSelectedProjects(projects.fullstack))} className={`option-header ${selectedHash === 'fullstack' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'fullstack' && getThemeColor(theme), color:selectedHash === 'fullstack' && 'var(--white)'}}>Fullstack</div>
                    <div onClick={() => setSelectedHash('fulltime', setSelectedProjects(projects.fulltime))} className={`option-header ${selectedHash === 'fulltime' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'fulltime' && getThemeColor(theme), color:selectedHash === 'fulltime' && 'var(--white)'}}>Fulltime</div>
                    <div onClick={() => setSelectedHash('college', setSelectedProjects(projects.college))} className={`option-header ${selectedHash === 'college' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'college' && getThemeColor(theme), color:selectedHash === 'college' && 'var(--white)'}}>College</div>
                    <div onClick={() => setSelectedHash('internship', setSelectedProjects(projects.internship))} className={`option-header ${selectedHash === 'internship' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'internship' && getThemeColor(theme), color:selectedHash === 'internship' && 'var(--white)'}}>Internship</div>
                    <div onClick={() => setSelectedHash('hackathon', setSelectedProjects(projects.hackathon))} className={`option-header ${selectedHash === 'hackathon' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'hackathon' && getThemeColor(theme), color:selectedHash === 'hackathon' && 'var(--white)'}}>Hackathon</div>
                    <div onClick={() => setSelectedHash('personal', setSelectedProjects(projects.personal))} className={`option-header ${selectedHash === 'personal' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'personal' && getThemeColor(theme), color:selectedHash === 'personal' && 'var(--white)'}}>Personal</div>
                </div>

                <div className={`col a-center full-w`} style={{width: '85%'}}>
                    {
                        (selectedProjects && selectedProjects.length > 0)? selectedProjects.map((project, index) => {
                            return (
                                <div key={index} className="testomonial-phone margin-t-xx" style={{ background: theme !== 'main' && (theme === 'light'? light : dark) }}>
                                    <div className="testomonial-header" style={{flexDirection: 'column'}}>
                                        <div className={`row full-w center-hv padding-x`} >
                                        <div className="testomonee-name t-center" style={{fontSize: '1.5rem'}}>{project.project_name}</div>
                                        </div>

                                        <div className="row full-w center-hv"  >
                                            <div className="font-lable-l white-color"  style={{ color: theme === 'light' && dark}}>{project.work_type}&nbsp;@&nbsp;</div>
                                             <div className="font-lable-l white-color" style={{ color: theme === 'light' && dark}}> ({project.org})</div>
                                        </div>
                                    </div>
                                    <div className="testomonial-body" style={{ padding: '1rem' }}>
                                        
                                        <div className="testomonial-body-text v-scroll" style={{fontSize: '1.1rem', height: 'unset', color: theme === 'light' && dark}}>{project.desc}</div>
                                        <div className={`row j-start a-center h-scroll`}>
                                            {project.tech_stack && project.tech_stack.length > 0 && (
                                                project.tech_stack.map((tech, index2) => {
                                                    return(
                                                        <div key={index2} className="fit-content-w fit-content-h col center-hv">
                                                            <img
                                                                src={tech.icon}
                                                                alt={"non"}
                                                                className="portfolio-tech-icon"
                                                            />
                                                        </div>
                                                    );
                                                })
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    
                     : (
                        <div className="full-w fit-content-h col center-hv">
                        <ScaleLoader
                            css={override}
                            height={'10rem'}
                            width={'2rem'}
                            color={"#f9f9f950"}
                            loading={true}
                        />
                        <div className="lable color-white margin-t-xx" style={{ fontSize: '1rem' }}>Loading From Server...</div>
                    </div>
                     )} 
                </div>
            </div>
        </div>
    );
}