import firebase from "../js/firebase";

let url = "https://us-central1-bashbio.cloudfunctions.net/app";
if(!window.location.href.includes('elbashah'))
url = "http://192.168.1.27:5051/bashbio/us-central1/app";

export const postTestimonial = (body) => POST(body, 'addTestimonial');
export const postTestimonialImage = (body) => POST(body, 'addTestimonialImage');

export const postNewVisitor = (body) => 
    POST(body, 'addVisitor')

export const postAnonynousVisitor = (body) => 
    POST(body, 'addAnonynousVisitor')
export const POST = async (body, endpoint) => {

  return fetch(`${url}/${endpoint}`, {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
  })
      .then(resp => resp.json())
      .then(obj => obj)
      .catch(error => console.log("\n[ERR]  @  [POST]  @  []\n[ERROR::MSG]:\n", error))
};