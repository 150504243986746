import React, {  useState, useEffect, useRef } from 'react';
import './desktop.css';
import {  Link,  } from "react-router-dom";
import { TextField } from '@material-ui/core';
import {
     welcome, welcome_response, defaults,
    greetings, greetings_response
} from '../assets/phrases';
import Typing from 'react-typing-animation';
import Cursor from '../assets/cursor';
import $ from 'jquery';
// import {ApiAiClient} from 'api-ai-javascript';
// const accessToken='';
// const client= new ApiAiClient({accessToken});

function getResponse(text) {
    let responses = [];
    let score = 0;
    let bot = [{ arr: welcome }, { arr: welcome_response }, { arr: greetings }, { arr: greetings_response }];
    for (let i = 0; i < bot.length; i = i + 2) {
        for (let n = 0; n < bot[i].arr.length; n++) {

            if(i === 0 || i === 2){
                if (bot[i].arr[n].includes(text.toUpperCase())) {
                    let random_index = Math.floor(Math.random() * bot[i].arr.length - 1);
                    let res = {
                        phrase: bot[i + 1].arr[random_index],
                    }
                    responses.push(res);
                    return responses;
                }
            }else{
                if (bot[i].arr[n].includes(text.toUpperCase())) {
                    score = score + 1;
                }

                if (n === bot[i].arr.length - 1) {
                    if (score >= (bot[i].arr.length / 2)) {
                        let random_index = Math.floor(Math.random() * bot[i].arr.length - 1);
                        let res = {
                            phrase: bot[i + 1].arr[random_index],
                        }
                        responses.push(res);
                        return responses;
                    }
                }
            }
        }
    }

    if (responses.length < 1) {
        let random_index = Math.floor(Math.random() * defaults.length - 1);
        // responses.push(defaults[random_index]);
        let res = {
            phrase: defaults[random_index],
        }
        responses.push(res);
    }

    return responses;
}

function Typer2() {
    return (

        <Typing speed={50} loop={true} cursor={<Cursor />}>
            <span>HELLO, THIS IS </span>
            <Typing.Speed ms={100} />
            <span> MOHAMMED ALBASHA</span>
            <Typing.Backspace count={33} speed={20} delay={1000} />
            {/* <Typing.Reset  delay={500} /> */}

            <Typing.Speed ms={100} />
            {/* <span> </span> */}
            <span>I'M A SOFTWARE ENGINEER</span>
            <Typing.Backspace count={17} speed={22} delay={500} />

            <Typing.Speed ms={100} />
            <span>FULL STACK WEB DEVELOPER</span>
            <Typing.Backspace count={24} speed={20} delay={500} />

            <Typing.Speed ms={100} />
            <span>FULL STACK MOBILE DEVLOPER</span>
            <Typing.Backspace count={26} speed={20} delay={500} />

            <Typing.Speed ms={100} />
            <span>BACKEND SYSTEM ARCHITECT</span>
            <Typing.Backspace count={24} speed={20} delay={500} />

            <Typing.Speed ms={100} />
            <span>TEAM PLAYER, WITH GREAT LEADERSHIP SKILLS</span>
            <Typing.Backspace count={51} speed={15} delay={800} />

            <Typing.Speed ms={100} />
            <span>I HOPE YOU ENJOYING MY PORTOFOLIO ^_^!</span>
            <Typing.Backspace count={45} speed={100} delay={1000} />

            <Typing.Speed ms={500} />
            <span>THANKS!</span>
            <Typing.Backspace count={10} speed={500} delay={2000} />

            <Typing.Reset speed={10} />
        </Typing>
    );
}

const Typer = obj => {
    if(obj.responses[0].phrase === undefined)
        return;
    else{
        if(obj.responses[0].phrase.includes('undefined'))
            return;
    }

    return (

        <Typing speed={50} loop={true} style={{}} cursor={<Cursor />} onFinishedTyping= {() => {
            obj.setTyperResponses([]);
        }}>
            {/* <Typing.Reset speed={10} /> */}
            {obj.responses.map(function (phrase) {
                let phr = phrase.phrase;
                if(phr === undefined){
                    return false;
                }
                    

                return (
                    <div>
                        <Typing.Speed ms={100} />
                        <span>{phrase.phrase !== undefined && phrase.phrase}</span>
                        <Typing.Backspace count={50} speed={20} delay={5500} />
                    </div>
                );

            })}

            <Typing.Reset speed={10} />
        </Typing>
    );
}

function onEnterClick(e, setMessages, messages, typerResponses, setTyperResponses) {
    if (e.keyCode === 13) {

        if (e.target.value !== undefined){
            if (e.target.value.length > 0){
                if (e.target.value !== " ") {
                    setMessages([...messages, { msg: e.target.value }]);

                    let responsesList = getResponse(e.target.value.toUpperCase());
    
                    if (responsesList[0].phrase !== undefined)
                        setTyperResponses(responsesList);
                    else {
                        responsesList[0].phrase = " WHAT WAS THAT...?"
                        setTyperResponses(responsesList);
                    }

                } else { setTyperResponses([]) }
            } 
        } 
  
        e.target.value = '';
    }
}

const BashChat = arr => {
    return (
        <div>
            {arr.arr.map(function (msg) {
                return (
                    <div className="message-holder">
                        <img src={require('../images/user-overlay.svg')} alt="botimg" class="msg-img" />
                        {/* <img src="../images/user-overlay.svg" alt="botimg" class="msg-img"></img> */}
                        <div class="usermessage">{msg.msg}</div>
                    </div>
                );

            })}

        </div >
    );
}

function BotDesktop() {

    const [messages, setMessages] = useState([]);
    const [typerResponses, setTyperResponses] = useState([]);

    const divRef = useRef(null);

    useEffect(() => {
    //   divRef.current.scrollIntoView({ behavior: 'smooth' });
    $("#bash-chat").animate({ scrollBottom: $('#bash-chat').prop("scrollHeight")}, 1000);
    }, [messages]);

    return (
        <div className="top-body">
            <div className="holder">
                <div className="main padding-xx " >

                    <div className="gradiant radius-x" style={{}}>
                        <div className="main-container" style={{flexDirection:'column'}}>
                            <div className="b-head" style={{ display: 'flex', height:'8rem'}}>
                                <div className="row" style={{ width: '100%', justifyContent: 'flex-end' }}>
                                    <div className="row" style={{}}>
                                        <div><Link to="/" className="link" style={{ borderTopLeftRadius: '0.3rem', borderBottomLeftRadius: '0.3rem' }}>PORTFOLIO</Link></div>
                                    </div>
                                </div>
                            </div>

                            <div className="b-body" style={{ height: '75%', }}>

                                <div className="typer-holder">
                                    <div className="typer">
                                        {typerResponses.length > 0 ?
                                            <Typer setTyperResponses={setTyperResponses} responses={typerResponses} id="typer" style={{ flexDirection: 'row', display: 'flex' }} />
                                            :
                                            <Typer2 id="typer" style={{ flexDirection: 'row', display: 'flex' }} />
                                        }
                                    </div>
                                </div>

                                <div className="chat-container" id="bash-chat">
                                    <div className="chat-holder" style={{}}>
                                        <div className="chat" ref={divRef} >
                                            <BashChat arr={messages} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="b-footer" style={{bottom:'5rem'}}>
                                <div className="txt" style={{}}>
                                    {/* <TextField id="outlined-basic" label="Outlined" variant="outlined"  className="" /> */}
                                    <TextField style={{
                                        backgroundColor: '#fff',
                                        border: '0.15rem rgb(238, 0, 118) solid',
                                        borderRadius: '0.3rem',
                                        width: '35vw',
                                        fontSize: '2rem',
                                    }}
                                        id="outlined-password-input"
                                        // label="Password"
                                        placeholder="Ask Me Anything!"
                                        type="text"
                                        autoComplete="current-password"
                                        variant="outlined"
                                        onKeyDown={e => onEnterClick(e, setMessages, messages, typerResponses, setTyperResponses)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BotDesktop;