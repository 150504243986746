/**
 *          LIBRARIES
 */
import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup  } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { FaQuoteLeft, FaQuoteRight, } from 'react-icons/fa';
import { light, dark, white, main, dimDark } from "../js/util";
import { getTestimonials } from "../js/get";
import FlatList from 'flatlist-react';
import { BarWave } from "react-cssfx-loading";

/**
 *          APP COMPONENTS
 */

/**
 *          JS FUNCTIONS & CONSTANTS
 */


export const LoadingRecs = ({theme}) => {

    return(
    <div className="col center-hv" style={{width: '20rem', height: '20rem'}}>
        <BarWave color={theme === 'light'? dark : white} width="150px" height="45px" />
        <div className={`font-lable-x t-center center-hv margin-t-xx`} style={{lineHeight: 1.5, fontWeight: 400, marginTop: '4rem', color: theme === 'light' && dark}}>
            Loading Testimonials...
        </div>
    </div>)
}
export default ({ theme}) => {

    const [testimonials, setTestimonials] = useState([])
    useEffect(() => {
        const getInfo = async () => {
            
            const newTestimonials = await getTestimonials();
            setTestimonials(newTestimonials)
        }
        getInfo()
    }, [])

    return (
        <div id="rec-slider">
        <CarouselProvider totalSlides={testimonials? testimonials.length : 0 } visibleSlides={1}>
            <Slider>
                <FlatList
                    list={testimonials}
                    key={performance.now()}
                    renderWhenEmpty={() => <LoadingRecs theme={theme} />}
                    renderItem={(testimonial, i) => {
                       
                        return(
                            <Slide index={i} key={window.performance.now()} style={{width: 'calc(100vw - 6rem)', height: '35rem', marginLeft: '3rem', marginRight: '3rem', marginBottom: '0.5rem', marginTop: '0.5rem'}} className="testomonial-holder margin-l-xxx">
                                <div className="testomonial-phone" style={{ background: theme === 'dark'? dark : (theme === 'light'? light : '') }}>
                                    <div className="testomonial-header" style={{flexDirection: 'column'}}>
                                        <div className="bash-image " style={{ justifyContent: 'center', display: 'flex' }}>
                                            <img src={testimonial.img} alt={'non'} style={{ width: '6rem',  height: '6rem', borderRadius: '100rem', 
                                            border: `0.15rem ${theme === 'light'? dark : (theme === 'dark'? main : 'rgb(238, 0, 118)') } solid`, marginTop: '2rem', }} />
                                        </div>

                                        <div className="testomonee-info" style={{marginLeft: '0rem'}}>
                                            <div className="testomonee-name" style={{fontSize: '2rem'}}>{testimonial.name}</div>
                                        <div className="testomonee-job t-center" style={{color: theme === 'light'? dark : ''}}>{testimonial.position} ({testimonial.org})</div>
                                        </div>
                                    </div>
                                    <div className="testomonial-body" style={{ padding: '1rem' }}>
                                        <div className="quote" style={{ display: 'flex', justifyContent: 'flex-start', color: '#ee7752' }}>
                                            <FaQuoteLeft />
                                        </div>
                                        <div className="testomonial-body-text v-scroll" style={{fontSize: '1.1rem', color: theme === 'light'? dark : ''}}>{testimonial.testimonial}</div>
                                        <div className="quote" style={{ display: 'flex', justifyContent: 'flex-end', color: '#ee7752' }}>
                                            <FaQuoteRight />
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                    )}}
                        
                />
            </Slider>
            <div id={'carousel-'+theme} className={`row j-center full-w margin-t-x`} >
                <DotGroup  />
            </div>
        </CarouselProvider>
        </div>
    );
}