/**
 *          LIBRARIES
 */
import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";

/**
 *          APP COMPONENTS
 */
import About from "./about";
import PhoneHeader from "./components/phone-header";
import Portfolio from "./portfolio";
import Resume from "./resume"

/**
 *          JS FUNCTIONS & CONSTANTS
 */
import { VIEWS } from "../navigation/views-stack";
import { getTestimonials, getProjects, getMyInfo } from "../js/get";
import { reArrangeProjectsPhone, extreemDark, white, dark, getDeviceUUID } from "../js/util";
import { TextBox, VisitorForm } from "../desktop/home-desktop";
import { postNewVisitor } from "../js/post";
import { auth } from "firebase";



 export default ({visitorForm, setVisitorForm}) => {
    const [viewStack, setViewStack] = useState({view: VIEWS.ABOUT, msg: ``});
    const [testimonials, setTestimonials] = useState([]);
    const [projects, setProjects] = useState([]);
    const [recVisible, setRecVisible] = useState(false);
    const selectedTheme = localStorage.getItem('theme')
    const [theme, setTheme] = useState(selectedTheme || 'main')
    // const vForm = localStorage.getItem('vForm')
    // const [visitorForm, setVisitorForm] = useState(visitor === null?  'true' : 'false')


    useEffect(() => {
      const getInfo = async () => {
        const newTestimonials = await getTestimonials();
        const newProjects = await getProjects();
        setTestimonials(newTestimonials, setProjects(reArrangeProjectsPhone(newProjects)));
        // submitVisitor(false, false, {}, null)
      }
      getInfo()
     
    }, []);

 return (
     <div className={`full-wh full-vh col gradiant-new`} style={{height: '100vh', background: theme === 'dark'? extreemDark : (theme === 'light'? white : '') }}>
         <PhoneHeader setViewStack={setViewStack} view={viewStack.view} theme={theme} setTheme={setTheme}/>
         <div className={`col a-center j-start full-wh v-scroll`}>
            {viewStack.view === VIEWS.ABOUT && <About theme={theme} setTheme={setTheme}  recVisible={recVisible} setRecVisible={setRecVisible} testimonials={testimonials} setViewStack={setViewStack}/>}
            {viewStack.view === VIEWS.PORTFOLIO && <Portfolio theme={theme} setTheme={setTheme} setViewStack={setViewStack}/>}
            {viewStack.view === VIEWS.RESUME && <Resume theme={theme} setTheme={setTheme} projects={projects} setViewStack={setViewStack}/>}
            
            <div className={`col center-hv full-w margin-t-xxx margin-b-x`}>
                <div className={`font-lan-m `} style={{color: theme === 'light'? dark : white}}>
                Copyrights &#169; belongs to author (
                    <a href="https://github.com/ElBashah" target="_blank" style={{ color: '#ee7752', fontWeight: '800' }}>Elbashah</a>)
            
                </div>
            </div>
         </div>

         
         {visitorForm === 'false' && <VisitorFormPhone setVisitorForm={setVisitorForm} theme={theme} />}
     </div>
 ); 
}

const submitVisitor = async (isSkip, save, form, setVisitorForm) => {

  const user = {
      uid: auth().currentUser.uid || getDeviceUUID(), 
      web_engine: navigator.userAgent, 
      platform_type: navigator.platform, 
      browser: `${navigator.appCodeName}-${navigator.vendor}`, 
     
      browser_language: window.navigator.language, 
      location: window.navigator.geolocation || {}, 
      name: form.name || '',
      org: form.org || '',
      skipped: isSkip
  }

  postNewVisitor(user)

  if(save){
    localStorage.setItem('vForm', 'false')
    setVisitorForm(false)
  }
    
}

export const VisitorFormPhone = ({theme, setVisitorForm}) => {
  const [visitors, setVisitors] = useState(0) 
  const [form, setForm] = useState({
      name: '',
      org: '',

  })

  useEffect(() => {
    const getInfo = async () => {
        const info = await getMyInfo();
        setVisitors(info?.visitors || 0);
    }
    
    getInfo()
  }, [])

  const handleFormChange = e => setForm({...form, [e.target.name]: e.target.value})
  
  return (
      <>
          <div className={`visitor-form-phone-container`}>
              <div className={`visitor-form-phone `}>
                  <div className={`font-title-m row t-center j-center margin-t-x`} style={{fontWeight: 900, lineHeight: '1.2'}}>Voluntary Self Disclosure</div>
                  <i className={`font-lable-m row j-center full-w t-center margin-t-x`} style={{fontWeight: 400}}>Welcome dear visitor, it will make me happy to know who you are.</i>

                    {
                        visitors > 1
                            ? <div className={`font-lable-l row j-center margin-t-xxx t-center`} style={{fontWeight: 900}}>You are the<b style={{color: 'var(--secondary_color)'}}>&nbsp;{visitors+1}&nbsp;</b> visitor</div>
                            : <div className={`font-lable-l row j-center margin-t-xxx t-center`} style={{fontWeight: 900}}>Calculating number of visitors...</div>
                    }
                  <div className={`j-center full-w row margin-t-xx`}>
                      <TextBox  value={form.name} onChange={handleFormChange} name="name" placeholder={'Name (Optional)'} id="txt-name" className={`txt`} autoFocus={true}  style={{ width: '100%' }} inputProps={{}} />
                  </div>
                  <div className={`j-center full-w row margin-t-x`}>
                      <TextBox value={form.org} onChange={handleFormChange} name="org" placeholder={'Organization (Optional)'} id="txt-name" className={`txt`} autoFocus={true}  style={{ width: '100%' }} inputProps={{}} />
                  </div>

                  <div className={`col full-w j-center margin-t-xx`}>
                  <div onClick={() => submitVisitor(false, true, form, setVisitorForm)} className={` white-color row center-hv font-title-l `} style={{ cursor:'pointer', color: theme !== 'main' && 'var(--main_color)', }}>Submit</div>
                  <div onClick={() => submitVisitor(true, true, form, setVisitorForm)} className={` row center-hv font-title-x`} style={{ cursor:'pointer', color: 'var(--secondary_color)', textDecoration: 'underline', marginTop: '3rem', }}>Skip</div>
                  </div>
              </div>
          </div>
      </>
  )
}