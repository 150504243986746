/**
 *          LIBRARIES
 */
import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";

/**
 *          APP COMPONENTS
 */
import CarsouelPhone from "./carsouel-phone";

/**
 *          JS FUNCTIONS & CONSTANTS
 */
import 'pure-react-carousel/dist/react-carousel.es.css';
import SkillsPhone from "./skills-phone";
import Recommendation from "./recommendation-form-phone";
import { background, dark, white, main, purple } from "../js/util";

export default ({testimonials, theme }) => {
    const [showRec, setRecVisible] = useState(false);
    const [recs, setRes] = useState(testimonials)

    return(
        <div className={` `} style={{marginTop: '5rem'}}>
            <div className="col">
                <div className="col">
                    <div className={`padding-xx full-w col`}>
                        <div className="about-name" style={{fontSize: '2.2rem', }}>Mohammed Albasha</div>
                        <div className="about-title" style={{fontSize: '1rem', marginTop: '1rem', color: theme === 'light'? dark : '' }}>Lead Software Engineer @ pass-me.com</div>
                        <div className="about-phone" style={{ color: theme === 'light'? dark : ''}}>
                       
                        I'm a Full-Stack Software Engineer, mainly developing scalable web & mobile applications. I'm currently pursuing my master’s degree in computer science and focusing on AI technologies in image processing, I will graduate in Fall 22. I'm currently looking for internships for Summer 22 and a Full-time position for Fall 22. 
        
                        </div>
                    </div>
                    <div className="full-w j-center row" >
                        <img src={require('../images/side_pic.jpg')} alt={'non'} style={{
                            width: '15rem',
                            height: '22rem', marginTop: '2rem',
                            border: `0.15rem ${theme === 'light'? dark : (theme === 'dark'? main : 'rgb(238, 0, 118)') } solid`,
                            borderRadius: '0.5rem',
                            opacity: '0.9'
                        }} />
                    </div>
                </div>


                <div className="margin-t-xxx" style={{  }}>
                    <div className="testomonials-holder">
                    <div className={`full-w row j-center font-title-x main-color margin-t-xxx margin-b-xx`}>Testimonials</div>
                        <div className="testomonials-slider" id="rec-carasoul">
                            <CarsouelPhone testimonials={testimonials} theme={theme} />
                        </div>
                        <div className="testomonials-add" onClick={() => setRecVisible(true)}>
                            <div className="add-holder" style={{width: '22rem', backgroundColor: theme === 'dark'? main : (theme === 'light'? dark : '')}}>
                                <div className="add-sign" style={{ color: theme !== 'main'? white : ''}}>+ &nbsp;</div>
                                <div className="add clickable-main" style={{fontSize: '1.2rem', }}>ADD YOUR TESTIMONIAL</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`full-w row j-center font-title-x main-color margin-t-xxx margin-b-xx`}>Skills</div>
                <SkillsPhone theme={theme} />
            </div>

            {showRec && (<Recommendation setRecVisible={setRecVisible} theme={theme} />)}
        </div>
    );
}