import React, { useEffect, useState } from 'react';
// import '../App.css';
import './desktop.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import { FaMapPin, FaFilePdf, FaPhoneAlt, FaGlobe} from 'react-icons/fa';
import { MdEmail, } from 'react-icons/md';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getMyInfo } from '../js/get';

function ResumeDesktop({theme}) {

    const tColor = theme === 'light' ? 'dark-color' : ''
    const button = theme === 'light' ? 'background-dark' : ( theme === 'dark' ? 'background-light' : '' )
    const btColor = theme === 'light' ? 'white-color' : ( theme === 'dark' ? 'dark-color' : '' )

    const [resumeLink, setResumeLink] = useState('');
     useEffect(() => {

        const getInfo = async () => {
            try{
                const res = await getMyInfo()
                setResumeLink(res.resume)
            }catch(e){
                // console.log("[ERR]@[RESUME]: ", e);
            }
            
        }
        getInfo()
        
     }, [])
    return (

        <div className="container-d" id="">
            <div className="head-d" style={{}}>
                <div style={{
                    display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column', padding: '1rem',
                    width: '100%', height: '20rem'
                }}>
                    <div style={{ fontSize: '4rem', color: '#ee7752', fontWeight: '500' }}>My Resume</div>
                    <div className={`pdf-btn ${button} `} style={{cursor: 'pointer'}}>
                        <div 
                            onClick={() => window.open(resumeLink, '_blank')}
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%' }}>
                            <FaFilePdf style={{ width: '1.5rem', height: '1.5rem', color: '#ee7752' }} />
                            <div className={` ${btColor}`} style={{ fontSize: '1.8rem', fontWeight: '500', height: '1.7rem' }}>Download</div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', width:'100%' }}><div className="rounded-seperator"></div></div>
                </div>
            </div>

            <div className="body-d" style={{ height: 'unset', justifyContent:'center' }}>
                <div className="resume-container" style={{ background: theme === 'dark'? 'var(--dark)' : (theme === 'light'? 'var(--light)' : '')}}>
                    <div className="resume-holder">

                        {/* HEIGHT */}
                        <div className="resume-header-holder">
                            <div className="resume-header">
                                <div className="resume-h-row-holder">
                                    <div className="resume-h-row">
                                        <div className="resume-h-l">
                                            <div className="resume-name">MOHAMMED ALBASHA</div>
                                            <div className={`resume-title ${tColor}`}>Junior Software Engineer</div>
                                        </div>
                                        <div className="resume-h-r">
                                            <div className="resume-h-contact">
                                                <div className="resume-h-contact-row">
                                                    <FaPhoneAlt style={{ width: '1.5rem', height: '1.5rem', color: '#ee7752' }}/>
                                                    &nbsp; &nbsp;
                                                    <div className={`resume-h-contact-row-v ${tColor}`}>+1 (480)-401-7023</div>
                                                </div>
                                                <div className="resume-h-contact-row">
                                                    <MdEmail style={{ width: '1.8rem', height: '1.8rem', color: '#ee7752' }}/>
                                                    &nbsp; &nbsp;
                                                    <div className={`resume-h-contact-row-v ${tColor}`} style={{}}>mohammed.a.albasha@gmail.com</div>
                                                </div>
                                                <div className="resume-h-contact-row">
                                                    <FaGlobe style={{ width: '1.5rem', height: '1.5rem', color: '#ee7752' }}/>
                                                    &nbsp; &nbsp;
                                                    <div className={`resume-h-contact-row-v ${tColor}`}>elbashah.me</div>
                                                </div>
                                                <div className="resume-h-contact-row">
                                                    <FaMapPin style={{ width: '1.5rem', height: '1.5rem', color: '#ee7752' }}/>
                                                    &nbsp; &nbsp;
                                                    <div className={`resume-h-contact-row-v ${tColor}`}>(Tempe - AZ), USA</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <br/>
                                <div style={{ display: 'flex', justifyContent: 'center', width:'130%', marginRight: '2rem' }}><div className="rounded-seperator"></div></div>
                                <br/>

                                <div className="resume-h-row-holder">
                                    <div className="resume-h-row">
                                        <div className="bash-image" style={{ justifyContent: 'center', display: 'flex' }}>
                                            <img src={require('../images/portfolio_pic.jpg')} alt={'non'} style={{
                                                width: '8rem',
                                                height: '8rem', borderRadius: '100rem', 
                                                border: `0.15rem ${theme === 'light'? 'var(--dark)' : (theme === 'dark'? 'var(--main_color)' : 'rgb(238, 0, 118)') } solid`,
                                            }} />
                                        </div>

                                        <div className={`resume-h-brief ${tColor}`}>
                                        
                                            I'm a Full-Stack Software Engineer, mainly developing scalable web & mobile applications. I'm currently pursuing my master’s degree in computer science and focusing on AI technologies in image processing, I will graduate in Fall 22. I'm currently looking for internships for Summer 22 and a Full-time position for Fall 22. 
        
                                        </div>
                                    </div>
                                </div>

                                <br/>
                                <div style={{ display: 'flex', justifyContent: 'center', width:'130%', marginRight: '2rem'
                            
                            }}><div className="rounded-seperator"></div></div>
                                <br/>
                            </div>
                        </div>

                        {/* BODY */}
                        <div className="resume-body-holder">
                            <div className="resume-body">
                                <div className="resume-b-l-holder">
                                    <div className="resume-b-l">
                                        <div className={`resume-b-title ${tColor}`}><div style={{backgroundColor:'#ee7752', width:'2rem', height:'2rem', color:'transparent', marginTop:'0.3rem', borderRadius: '5rem'}}>&nbsp;</div>&nbsp; EXPERIENCES</div>

                                        <div className="resume-b-l-r">
                                            <div className="resume-b-l-r-v">
                                                <div className={`resume-b-l-r-v-title ${tColor}`}>Junior Software Engineer & Lead Engineer @ Pass-me.com</div>
                                                <div className={`resume-b-l-r-v-org ${tColor}`}>CloudApps | 02-2020 - present</div>
                                            </div>
                                            <div className="resume-b-l-r-v" style={{height:'unset'}}>
                                                <div className={`resume-b-l-v-brief ${tColor}`}>
                                                I worked on developing Point of Sale (POS) UI, REST APIs, and then I was assigned to be the lead engineer to 
                                                build a Digital QR Menu (Pass-me.com) as SPA web app. In this job I mainly used,&nbsp;
                                                <b className={`tech-hashtag`}>#React-JS</b>, <b className={`tech-hashtag`}>#React-Native</b>, <b className={`tech-hashtag`}>#Node-JS</b>, <b className={`tech-hashtag`}>#Firebase</b>, and <b className={`tech-hashtag`}>#Android</b>. I also used for short time, <b className={`tech-hashtag`}>#Cordova</b>, <b className={`tech-hashtag`}>#Electron-JS</b>, <b className={`tech-hashtag`}>#Swift</b>, <b className={`tech-hashtag`}>#Angular-JS</b>.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="resume-b-l-r">
                                            <div className="resume-b-l-r-v margin-t-x" style={{height:'unset'}}>
                                                <div className={`resume-b-l-r-v-title ${tColor}`}>GSA (Web Developer)</div>
                                                <div className={`resume-b-l-r-v-org ${tColor}`}>ASU | 10-2021 - 01-2022</div>
                                            </div>
                                            <div className="resume-b-l-r-v" style={{height:'unset', }}>
                                                <div className={`resume-b-l-v-brief ${tColor}`}>
                                                I worked on developing a website to gather information of human interaction with a simulation of robot monitoring.
                                                  In this job I mainly used,&nbsp;
                                                <b className={`tech-hashtag`}>#Flask</b>, <b className={`tech-hashtag`}>#Python</b>, <b className={`tech-hashtag`}>#Java-Script</b>, 
                                                <b className={`tech-hashtag`}>#Firebase</b>, <b className={`tech-hashtag`}>#HTML</b>, and <b className={`tech-hashtag`}>#CSS</b>.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="resume-b-l-r">
                                            <div className="resume-b-l-r-v margin-t-x" style={{height:'unset'}}>
                                                <div className={`resume-b-l-r-v-title ${tColor}`}>Full-Stack Software Developer</div>
                                                <div className={`resume-b-l-r-v-org ${tColor}`}>Khusheim | 07-2013 - 09-2014</div>
                                            </div>
                                            <div className="resume-b-l-r-v" style={{height:'unset'}}>
                                                <div className={`resume-b-l-v-brief ${tColor}`}>
                                                I worked on fixing bugs in their POS, built HR system, and analyzed & developed business needs from existing modules in 
                                                IFS ERP to the company .NET application to lower the annual costs of IFS licenses.  In this job I mainly used,

                                                <b className={`tech-hashtag`}>#VB</b>, <b className={`tech-hashtag`}>#C#</b>, <b className={`tech-hashtag`}>#ASP</b>, <b className={`tech-hashtag`}>#Java-Script</b>, <b className={`tech-hashtag`}>#CSS</b>,
                                                <b className={`tech-hashtag`}>#IFS</b>, <b className={`tech-hashtag`}>#SQL-Navigator</b>, <b className={`tech-hashtag`}>#Oracle-10g</b>, and <b className={`tech-hashtag`}>#SQL-Server</b>.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="resume-b-l-r">
                                            <div className="resume-b-l-r-v margin-t-x" style={{height:'unset'}}>
                                                <div className={`resume-b-l-r-v-title ${tColor}`}>Fellwship</div>
                                                <div className={`resume-b-l-r-v-org ${tColor}`}>DraperU & Misk | 10-2019 - 01-2020</div>
                                            </div>
                                            <div className="resume-b-l-r-v" style={{height:'unset'}}>
                                                <div className={`resume-b-l-v-brief ${tColor}`}>
                                                I was honored to be a fellow in the Misk fellowship program associated with DraperU in Silicon Valley. I was mainly learning more about the entrepreneurial mindset and how it can help me thrive. I worked voluntarily with a venture capital firm to help me understand more about entrepreneurs.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="resume-b-l-r">
                                            <div className="resume-b-l-r-v margin-t-x" style={{height:'unset'}}>
                                                <div className={`resume-b-l-r-v-title ${tColor}`}>Undergrad Teaching Assistant</div>
                                                <div className={`resume-b-l-r-v-org ${tColor}`}>Arizona State University | 08-2016 - 05-2019</div>
                                            </div>
                                            <div className="resume-b-l-r-v" style={{height:'unset'}}>
                                                <div className={`resume-b-l-v-brief ${tColor}`}>
                                                Working as UGTA increased my knowledge about the digital world. I was mainly responsible for 3 routine tasks. 1- Preparing for next lecture. 2- Taking student questions, and providing extra examples to help them overcome their concerns. 3- Prepare review sessions before each exam. 4- Hold office hours to help students after class time. I enjoyed helping students and working in a college environment where I meet new people every semester.               
                                                </div>
                                            </div>
                                        </div>

                                        <br/>
                                        {/* Projects */}
                                        <div className={`resume-b-title ${tColor}`}><div style={{backgroundColor:'#ee7752', width:'2rem', height:'2rem', color:'transparent', marginTop:'0.3rem', borderRadius: '5rem'}}>&nbsp;</div>&nbsp; Top 5 Projects</div>

                                        <div className="resume-b-l-r">
                                            <div className="resume-b-l-r-v">
                                                <div className={`resume-b-l-r-v-title ${tColor}`}>Pass-Me</div>
                                                <div className={`resume-b-l-r-v-org ${tColor}`} style={{ height: '3rem' }}>Full Stack
                                                <div className="project-technologies">
                                                        <img src={require('../images/react.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/css.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/fb.png')} alt={'non'} className="tech-icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="resume-b-l-r-v" style={{height:'unset'}}>
                                                <div className={`resume-b-l-v-brief ${tColor}`}>
                                                Pass-Me was at first an idea that I had at the time, aiming to attract big clients into buying LazyWait POS. 
                                                It's mainly about making a restaurant menu digital in a web page through a QR code and giving the clients' 
                                                customers the flexibility in viewing, submitting and paying an order without being in line or wait for the 
                                                waiter while dine-in. At the time I developed a prototype using ReactJS & Firebase and pitched it in-front all the employees at 
                                                CloudApps which everyone liked idea. Thus I had the green light into taking the lead in developing Pass-Me all from scratch. 
                                                I designed the full system architecture analyzing the work flow and APIs communication flow between Pass-Me API and the client 
                                                API for retrieving menu items and meta data to posting orders and other fundamental data for the client business logic. 
                                                Now Pass-Me became a huge project that have 4 sub-projects: Digital QR Menu, Dashboard, Public & Private REST APIs and Orders Terminal. 
                                                Pass-Me growth in it's first year was huge, as Pass-Me has been used by more than 
                                                <b className={`tech-hashtag`}>80 clients</b>, had more than 
                                                <b className={`tech-hashtag`}>163K visitors</b> and served more than 
                                                <b className={`tech-hashtag`}>24K customers</b> that submitted more than 
                                                <b className={`tech-hashtag`}>45K orders</b> with a total value of more than 
                                                <b className={`tech-hashtag`}>$1.1 million dollars</b>  
                                                &nbsp;<a href={`pass-me.com`} alt="Pass-Me.com">visit pass-me.com</a>.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="resume-b-l-r">
                                            <div className="resume-b-l-r-v">
                                                <div className={`resume-b-l-r-v-title ${tColor}`}>LazyWait POS</div>
                                                <div className={`resume-b-l-r-v-org ${tColor}`} style={{ height: 'unset' }}>Full stack
                                                <div className="project-technologies">
                                                        <img src={require('../images/html.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/css.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/angular.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/fb.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/node.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/swift.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/android.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/cordova.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/electronjs.png')} alt={'non'} className="tech-icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="resume-b-l-r-v" style={{height:'unset'}}>
                                                <div className={`resume-b-l-v-brief ${tColor}`}>
                                                I worked on changing the entire UI design and backend functionality for a POS. At first I was part of transferring from a regular server hosted on AWS to firebase, including the huge change from SQL based Database to NoSQL Database. I worked on implementing authentication for existing users in the old backend and new users with firebase. I worked on making the system support more than 20 languages by utilizing google translations. I developed an in app setup wizard alongside subscription plans to allow new users to setup the device with the POS and pay for a new licence. I also added features for old system users to migrate to the new system by multi-authentication process with firebase and the old system.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="resume-b-l-r">
                                            <div className="resume-b-l-r-v">
                                                <div className={`resume-b-l-r-v-title ${tColor}`}>LW POS complementary projects</div>
                                                <div className={`resume-b-l-r-v-org ${tColor}`} style={{ height: 'unset' }}>Frontend 
                                                <div className="project-technologies">
                                                        <img src={require('../images/react.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/fb.png')} alt={'non'} className="tech-icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`resume-b-l-r-v ${tColor}`} style={{height:'8rem'}}>
                                                <div className={`resume-b-l-v-brief ${tColor}`}>
                                                I worked on LazyWait POS complementary projects, including LW Mobile app for customers to order online and LW Admin Mobile App for clients to control their shops online. I developed features in both UI and the backend, including loyalty points wallet, orders monitoring, searching restaurants on the map. 
                                                </div>
                                            </div>
                                        </div> 

                                        {/* <div className="resume-b-l-r">
                                            <div className="resume-b-l-r-v">
                                                <div className="resume-b-l-r-v-title">LazyWait Admin App</div>
                                                <div className="resume-b-l-r-v-org" style={{ height: 'unset' }}>Full stack
                                                <div className="project-technologies">
                                                        <img src={require('../images/react.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/fb.png')} alt={'non'} className="tech-icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="resume-b-l-r-v" style={{height:'8rem'}}>
                                                <div className="resume-b-l-v-brief">
                                                    I made new features for business owners to allow them to be in full control of their business. By monitoring new orders, managing delivery orders, managing special orders and event bookings as well. Also I enhanced the UI, maintained code durability and lowered unnecessary api calls.
                                                </div>
                                            </div>
                        </div> */}

                                        <div className="resume-b-l-r">
                                            <div className="resume-b-l-r-v">
                                                <div className={`resume-b-l-r-v-title ${tColor}`}>This Beautiful Website</div>
                                                <div className={`resume-b-l-r-v-org ${tColor}`} style={{ height: 'unset' }}>Full stack
                                                <div className="project-technologies">
                                                        <img src={require('../images/react.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/node.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/fb.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/html.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/css.png')} alt={'non'} className="tech-icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="resume-b-l-r-v" style={{height:'unset'}}>
                                                <div className={`resume-b-l-v-brief ${tColor}`}>
                                                    For this amazing special project of mine, I created this react project from scratch with the passion to make it as my official web page. I mainly worked on front-end UI development and some back-end development using firebase, to make it dynamic and to give myself control in the future without the need of redeploying. As all my current information is in the cloud and I can edit from the cloud at any time. As I mentioned in my summary, I’m big fan of cloud technologies ^_^!
                                                </div>
                                            </div>
                                        </div>

                                        <div className="resume-b-l-r">
                                            <div className="resume-b-l-r-v">
                                                <div className={`resume-b-l-r-v-title ${tColor}`}>Ameen.it egov services</div>
                                                <div className={`resume-b-l-r-v-org ${tColor}`} style={{ height: 'unset' }}>Frontend
                                                <div className="project-technologies">
                                                        <img src={require('../images/js.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/node.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/fb.png')} alt={'non'} className="tech-icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="resume-b-l-r-v" style={{height:'unset'}}>
                                                <div className={`resume-b-l-v-brief ${tColor}`}>
                                                    This is another special project that I won 3rd place with at DraperU hackathon in 2019. It was based on the idea of smart eGov services with the power of AI. I took the lead role in complete development of the front -end and ensured browser and screen size compatibility for all different types of devices.
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="resume-b-l-r">
                                            <div className="resume-b-l-r-v">
                                                <div className="resume-b-l-r-v-title">Mawjood App</div>
                                                <div className="resume-b-l-r-v-org" style={{ height: 'unset' }}>Full stack
                                                <div className="project-technologies">
                                                        <img src={require('../images/react.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/node.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/fb.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/aws.png')} alt={'non'} className="tech-icon" style={{borderRadius:'1rem'}}/>
                                                        <img src={require('../images/serverless.png')} alt={'non'} className="tech-icon" style={{borderRadius:'1rem', marginLeft:'0.3rem'}}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="resume-b-l-r-v" style={{height:'10rem'}}>
                                                <div className="resume-b-l-v-brief">
                                                    This app is still in progress, it’s a side project of myself to become an entrepreneur in the future. I worked as a full-stack developer, hacked my way through making the backend with AWS, and later changed to firebase to lower prospect costs in the future. The front-end at first I made it with native android and swift, however at the first stages I decided it will be better to move to cross-platform technology to lower the time of development. At the time I was craving to learn React Native, thus I started hacking my way through it with React Native, please feel free to check my progress at github.
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>
                                </div>
                                <div className="resume-b-r-holder">
                                    <div className="resume-b-r">

                                        <div className={`resume-b-title ${tColor}`}><div style={{ backgroundColor: '#ee7752', width: '2rem', height: '2rem', color: 'transparent', borderRadius: '5rem',  marginTop: '0.3rem' }}>&nbsp;</div>&nbsp; Education</div>

                                        <div className="resume-b-r-r" style={{height:'6rem'}}>
                                            <div className="resume-b-r-r-v">
                                                <div className={`resume-b-r-r-v-major ${tColor}`}>M.S. Computer Science</div>
                                                <div className={`resume-b-r-r-v-school ${tColor}`} style={{marginTop:'0.5rem'}}>Arizona State University</div>
                                                <div className={`resume-b-r-r-v-school ${tColor}`}>Graduating Fall 2022</div>
                                            </div>
                                        </div>

                                        <div className="resume-b-r-r" style={{height:'9rem', width:'19rem'}}>
                                            <div className="resume-b-r-r-v">
                                                <div className={`resume-b-r-r-v-major ${tColor}`}>B.S. Software Engineering</div>
                                                <div className={`resume-b-r-r-v-school ${tColor}`} style={{marginTop:'0.5rem'}}>Arizona State University</div>
                                                <div className={`resume-b-r-r-v-school ${tColor}`}>Graduated Spring 2019</div>
                                                <div className={`resume-b-r-r-v-school ${tColor}`}>GPA 3.45 / 4 <div className={` ${tColor}`} style={{fontSize:'1.2rem', fontWeight:'500', color:'#f9f9f9'}}>(<i className={` ${tColor}`} style={{fontWeight:'500',}}>cum laude</i>)</div></div>
                                            </div>
                                        </div>

                                        <div className="resume-b-r-r" style={{height:'10rem'}}>
                                            <div className="resume-b-r-r-v">
                                                <div className={`resume-b-r-r-v-major ${tColor}`}>Computer Programming</div>
                                                <div className={`resume-b-r-r-v-school ${tColor}`} style={{marginTop:'0.5rem'}}>Institute of Public Administration (SAU)</div>
                                                <div className={`resume-b-r-r-v-school ${tColor}`}>Graduated Spring 2013</div>
                                                <div className={`resume-b-r-r-v-school ${tColor}`}>GPA 4.28 / 5 <div className={` ${tColor}`} style={{fontSize:'1.2rem', fontWeight:'500', color:'#f9f9f9'}}>(<i className={` ${tColor}`} style={{fontWeight:'500',}}>honors</i>)</div></div>
                                            </div>
                                        </div>

                                        <div className={`resume-b-title ${tColor}`} style={{marginTop: '3rem'}}><div style={{ backgroundColor: '#ee7752', width: '2rem', height: '2rem', color: 'transparent', borderRadius: '5rem',  marginTop: '0.3rem' }}>&nbsp;</div>&nbsp; Skills</div>

                                        <div className={` resume-b-r-r-v-major ${tColor}`} style={{marginTop:'1.5rem'}}>Technical</div>
                                        <div className="resume-b-r-r" style={{ height: '45rem', marginTop:'0rem' }}>
                                            <div className="resume-b-r-r-v" style={{justifyContent:'space-evenly'}}>
                                                <div className={` resume-b-l-r-v-org ${tColor}`} style={{ height: '3rem', width:'100%', justifyContent:'space-between' }}>Mobile Apps
                                                    <div className="project-technologies">
                                                        <img src={require('../images/react.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/android.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/swift.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/cordova.png')} alt={'non'} className="tech-icon" />
                                                    </div>
                                                </div>

                                                <div className="resume-b-l-r-v-org" style={{ height: '3rem', width:'100%', justifyContent:'space-between' }}>
                                                    <div className={` ${tColor}`}>Web Apps</div>
                                                    <div className="project-technologies">
                                                        <img src={require('../images/react.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/angular.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/html.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/css.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/js.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/python.png')} alt={'non'} className="tech-icon" />
                                                    </div>
                                                </div>

                                                <div className="resume-b-l-r-v-org" style={{ height: '3rem', width:'100%', justifyContent:'space-between' }}>
                                                    <div className={` ${tColor}`}>Web Servers</div>
                                                    <div className="project-technologies">
                                                        <img src={require('../images/node.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/tomcat.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/spring.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/cs.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/cpp.png')} alt={'non'} className="tech-icon" />
                                                    </div>
                                                </div>

                                                <div className="resume-b-l-r-v-org" style={{ height: '3rem', width:'100%', justifyContent:'space-between' }}>
                                                    <div className={` ${tColor}`}>Cloud Services</div>
                                                    <div className="project-technologies">
                                                        <img src={require('../images/aws.png')} alt={'non'} className="tech-icon" style={{borderRadius:'1rem'}} />
                                                        <img src={require('../images/fb.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/serverless.png')} alt={'non'} className="tech-icon" style={{borderRadius:'1rem'}}/>
                                                    </div>
                                                </div>

                                                <div className="resume-b-l-r-v-org" style={{ height: '3rem', width:'100%', justifyContent:'space-between' }}>
                                                    <div className={` ${tColor}`}>Web Technologies</div>
                                                    <div className="project-technologies">
                                                        <img src={require('../images/docker.png')} alt={'non'} className="tech-icon" style={{width:'2.5rem', height:'2.5rem'}}/>
                                                        <img src={require('../images/api.png')} alt={'non'} className="tech-icon" />
                                                    </div>
                                                </div>

                                                <div className="resume-b-l-r-v-org" style={{ height: '3rem', width:'100%', justifyContent:'space-between' }}>
                                                    <div className={` ${tColor}`}>Desktop Apps</div>
                                                    <div className="project-technologies">
                                                        <img src={require('../images/java.png')} alt={'non'} className="tech-icon" style={{borderRadius:'1rem', backgroundColor:'#f9f9f9'}}/>
                                                        <img src={require('../images/cs.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/cordova.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/electronjs.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/vb.png')} alt={'non'} className="tech-icon" />
                                                    </div>
                                                </div>

                                                <div className="resume-b-l-r-v-org" style={{ height: '3rem', width:'100%', justifyContent:'space-between' }}>
                                                    <div className={` ${tColor}`}>Relational Databases</div>
                                                    <div className="project-technologies">
                                                        <img src={require('../images/mysql.png')} alt={'non'} className="tech-icon" style={{borderRadius:'1rem', backgroundColor:'#f9f9f9'}}/>
                                                        <img src={require('../images/oracle.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/mssql.png')} alt={'non'} className="tech-icon" style={{borderRadius:'1rem', backgroundColor:'#f9f9f9'}}/>
                                                    </div>
                                                </div>

                                                <div className="resume-b-l-r-v-org" style={{ height: '3rem', width:'100%', justifyContent:'space-between' }}>
                                                    <div className={` ${tColor}`}>Team Work</div>
                                                    <div className="project-technologies">
                                                        <img src={require('../images/github.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/slack.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/bitbucket.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/agile.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/discord.png')} alt={'non'} className="tech-icon" style={{borderRadius:'1rem', backgroundColor:'#f9f9f9'}}/>
                                                    </div>
                                                </div>


                                                <div className="resume-b-l-r-v-org" style={{ height: '3rem', width:'100%', justifyContent:'space-between' }}>
                                                    <div className={` ${tColor}`}>Operating Systems</div>
                                                    <div className="project-technologies">
                                                        <img src={require('../images/windows.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/apple.png')} alt={'non'} className="tech-icon" />
                                                        <img src={require('../images/ubuntu.png')} alt={'non'} className="tech-icon" />
                                                    </div>
                                                </div>

                                                <div className={`resume-b-r-r-v-major ${tColor}`} style={{marginTop:'1.5rem'}}>Professional</div>

                                                <div className="resume-b-r-r" style={{ height: '10rem', width: '100%', marginTop:'0rem', padding:'0rem' }}>
                                                    <div className="resume-b-r-r-v" style={{ justifyContent:'space-evenly' }}>
                                                        <div className={`resume-b-r-r-v-school ${tColor}`}>Problem Solver</div>
                                                        <div className={`resume-b-r-r-v-school ${tColor}`}>Professional Communication</div>
                                                        <div className={`resume-b-r-r-v-school ${tColor}`}>Team Player</div>
                                                        <div className={`resume-b-r-r-v-school ${tColor}`}>Time Management</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Languages */}
                                        <div className={`resume-b-title ${tColor}`} style={{marginTop: '2rem'}}><div  style={{ backgroundColor: '#ee7752', width: '2rem', height: '2rem', color: 'transparent', borderRadius: '5rem',  marginTop: '0.3rem' }}>&nbsp;</div>&nbsp; Languages</div>

                                        <div style={{display:'flex', flexDirection:'row', alignItems:'baseline'}}>
                                            <div className={`resume-b-r-r-v-major ${tColor}`} style={{marginTop:'1.5rem'}}>English</div>
                                            {/* <div className="resume-b-r-r-v-major" style={{marginTop:'1.5rem'}}>(Professional)</div> */}
                                            <div style={{fontSize:'1.2rem', fontWeight:'500', color:'#f9f9f9', opacity:0.5}}><i className={` ${tColor}`} style={{fontWeight:'500',}}>&nbsp;(Professional)</i></div>
                                        </div>

                                        <div style={{display:'flex', flexDirection:'row', alignItems:'baseline'}}>
                                            <div className={`resume-b-r-r-v-major ${tColor}`} style={{marginTop:'1.5rem'}}>Arabic</div>
                                            {/* <div className="resume-b-r-r-v-major" style={{marginTop:'1.5rem'}}>(Native)</div> */}
                                            <div style={{fontSize:'1.2rem', fontWeight:'100', color:'#f9f9f9', opacity:0.5}}><i className={` ${tColor}`} style={{fontWeight:'500',}}>&nbsp;(Native)</i></div>
                                        </div>


                                        {/* Certificates */}
                                        <div className={`resume-b-title ${tColor}`} style={{marginTop: '4rem'}}><div  style={{ backgroundColor: '#ee7752', width: '2rem', height: '2rem', color: 'transparent', borderRadius: '5rem',  marginTop: '0.3rem' }}>&nbsp;</div>&nbsp; Certificates</div>

                                        <div style={{display:'flex', flexDirection:'row', alignItems:'baseline'}}>
                                        <div className={`resume-b-r-r-v-major ${tColor}`} style={{marginTop:'1.5rem'}}>SCE</div>
                                            <div className={`resume-b-r-r-v-school ${tColor}`} style={{marginTop:'1.5rem'}}>&nbsp;Certified Engineer</div>
                                        </div>

                                        <div style={{display:'flex', flexDirection:'row', alignItems:'baseline'}}>
                                        <div className={`resume-b-r-r-v-major ${tColor}`} style={{marginTop:'1.5rem'}}>DraperU</div>
                                            <div className={`resume-b-r-r-v-school ${tColor}`} style={{marginTop:'1.5rem'}}>&nbsp;Startup Hero</div>
                                        </div>

                                        {/* Research Intrests */}
                                        <div className={`resume-b-title ${tColor}`} style={{marginTop: '4rem'}}><div  style={{ backgroundColor: '#ee7752', width: '2rem', height: '2rem', color: 'transparent', borderRadius: '5rem',  marginTop: '0.3rem' }}>&nbsp;</div>&nbsp; Research Intrests</div>

                                        <div style={{display:'flex', flexDirection:'row', alignItems:'baseline'}}>
                                        <div className={`resume-b-r-r-v-major ${tColor}`} style={{marginTop:'1.5rem'}}>ML</div>
                                        <div className={`resume-b-r-r-v-school ${tColor}`} style={{marginTop:'1.5rem'}}>&nbsp;in Image Processing</div>
                                        </div>

                                        <div style={{display:'flex', flexDirection:'row', alignItems:'baseline'}}>
       
                                        </div>

                                        <div style={{display:'flex', flexDirection:'row', alignItems:'baseline'}}>
                                        <div className={`resume-b-r-r-v-major ${tColor}`} style={{marginTop:'1.5rem'}}>ML</div>
                                        <div className={`resume-b-r-r-v-school ${tColor}`} style={{marginTop:'1.5rem'}}>&nbsp;in Heart Rate & BP</div>
                                        </div>

                                        <div style={{ width: '100%', height: '20rem', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection:'column', marginTop:'2rem' }}>
                                            <img src={require('../images/elbashah.me.png')} alt={'non'} style={{ width: '15rem', height: '15rem', marginTop: '2rem', backgroundColor:'#ee7522', borderRadius:'1rem' }} />
                                            <div className={`resume-b-r-r-v-school ${tColor}`} style={{marginTop:'1rem'}}>Please Visit My Page</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="footer-d" style={{}}>
                <div className={`copy-right ${tColor}`}>Copyrights &#169; belongs to author (
                    <a href="https://github.com/ElBashah" target="_blank" style={{ color: '#ee7752', fontWeight: '800' }}>Elbashah</a>)</div>
            </div>
        </div>
    );
}

export default ResumeDesktop;