import React, { useState, useEffect } from 'react';
import SideMenuDesktop from './side-menu-desktop';
import AboutDesktop from './about-desktop';
import PortfolioDesktop from './portfolio-desktop';
import ResumeDesktop from './resume-desktop';
import ContactsDesktop from './contacts-desktop';

import { getTestimonials, getProjects, getMyInfo } from "../js/get";
import { reArrangeProjects, extreemDark, white, getDeviceUUID } from "../js/util";
import { TextField } from '@material-ui/core';
import { postNewVisitor } from '../js/post';
import { auth } from 'firebase';

function HomeDesktop({ setRecVisible, theme, setTheme, visitorForm, setVisitorForm }) {

    const [selectedPage, setSelectedPage] = useState('about');
    const [testimonials, setTestimonials] = useState([]);
    const [projects, setProjects] = useState([]);

    // const vForm = localStorage.getItem('vForm')
    // const [visitorForm, setVisitorForm] = useState(vForm || 'true')

    useEffect(() => {
      
        const getInfo = async () => {
            const newTestimonials = await getTestimonials();
            const newProjects = await getProjects();
            setTestimonials(newTestimonials, setProjects(reArrangeProjects(newProjects)));
            // submitVisitor(false, false, {}, null)
        }
        getInfo()
    }, []);

    return (

        <div className="top-body" id="root">
          {/* <div style={{height: '3rem', width: '100%', backgroundColor: '#fff', display:'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1.5rem'}}>This Project Still Under Development  {`</>`}</div> */}
            <div className="gradiant" style={{background: theme === 'dark'? extreemDark : (theme === 'light'? white : '')}}>
                <div className="main-container">
                    <SideMenuDesktop setSelectedPage={setSelectedPage} theme={theme} setTheme={setTheme}/>
                    {selectedPage === 'about' && <AboutDesktop theme={theme} testimonials={testimonials} setRecVisible={setRecVisible} />}
                    {selectedPage === 'portfolio' && <PortfolioDesktop theme={theme} projects={projects}/>}
                    {selectedPage === 'resume' && <ResumeDesktop theme={theme}/>}
                    {/* {selectedPage === 'contacts' && <ContactsDesktop/>} */}
                   
                    {visitorForm === 'false' && <VisitorForm setVisitorForm={setVisitorForm} theme={theme} />}
                </div>
            </div>
        </div>

    );
}

export const VisitorForm = ({theme, setVisitorForm}) => {
    const [visitors, setVisitors] = useState(0)
    const [form, setForm] = useState({
        name: '',
        org: '',

    })

    useEffect(() => {
        const getInfo = async () => {
            const info = await getMyInfo();
            setVisitors(info?.visitors || 0);
        }
        getInfo()
    }, [])

    const handleFormChange = e => setForm({...form, [e.target.name]: e.target.value})
    
    return (
        <>
            <div className={`visitor-form-container`}> 
                <div className={`visitor-form `}>
                    <div className={`font-title-x row j-center margin-t-xxx t-center`} style={{fontWeight: 900}}>Voluntary Self Disclosure</div>
                    <i className={`font-lable-m row j-center full-w t-center margin-t-x`} style={{fontWeight: 400}}>Welcome dear visitor, it will make me happy to know who you are.</i>
                    
                    {
                        visitors > 1
                            ? <div className={`font-lable-l row j-center margin-t-xxx t-center`} style={{fontWeight: 900}}>You are the<b style={{color: 'var(--secondary_color)'}}>&nbsp;{visitors+1}&nbsp;</b> visitor</div>
                            :<div className={`font-lable-l row j-center margin-t-xxx t-center`} style={{fontWeight: 900}}>Calculating number of visitors...</div>
                    }

                    <div className={`j-center full-w row margin-t-xxx`}>
                        <TextBox  value={form.name} onChange={handleFormChange} name="name" placeholder={'Name (Optional)'} id="txt-name" className={`txt`} autoFocus={true}  style={{ width: '100%' }} inputProps={{}} />
                    </div>
                    <div className={`j-center full-w row margin-t-x`}>
                        <TextBox value={form.org} onChange={handleFormChange} name="org" placeholder={'Organization (Optional)'} id="txt-name" className={`txt`} autoFocus={true}  style={{ width: '100%' }} inputProps={{}} />
                    </div>

                    <div className={`col full-w j-center margin-t-xx`}>
                    <div onClick={() => submitVisitor(false, true, form, setVisitorForm)} className={` white-color row center-hv font-title-l `} style={{ cursor:'pointer', color: theme !== 'main' && 'var(--main_color)', }}>Submit</div>
                    <div onClick={() => submitVisitor(true, true, form, setVisitorForm)} className={` row center-hv font-title-x`} style={{ cursor:'pointer', color: 'var(--secondary_color)', textDecoration: 'underline', marginTop: '4rem', }}>Skip</div>
                    </div>
                </div>
            </div>
        </>
    )
}

const submitVisitor = async (isSkip, save, form, setVisitorForm) => {

    const user = {
        uid: auth().currentUser.uid || getDeviceUUID(), 
        web_engine: navigator.userAgent, 
        platform_type: navigator.platform, 
        browser: `${navigator.appCodeName}-${navigator.vendor}`, 
       
        browser_language: window.navigator.language, 
        location: window.navigator.geolocation || {}, 
        name: form.name || '',
        org: form.org || '',
        skipped: isSkip
    }
  
    postNewVisitor(user)
  
    if(save){
      localStorage.setItem('vForm', 'false')
      setVisitorForm(false)
    }
      
  }

export const TextBox = ({
    value, 
    required, 
    onChange, 
    placeholder,
    className,
    type,
    name,
    id
 }) => {

    return(
        <>
            <div className={`textbox-continer`}>
                <input 
                    className={`textbox ${className || ''}`}
                    type={type || 'text'}
                    value={value}
                    required={required || false}
                    name={name || ''}
                    id={id || ''}
                    onChange={onChange}
                    placeholder={placeholder || ''}
                />

            </div>
        </>
    )
}
export default HomeDesktop;
