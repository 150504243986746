import React, {  useState } from "react";
import "../App.css";
import "./desktop.css";
import "semantic-ui-css/semantic.min.css";
import "react-alice-carousel/lib/alice-carousel.css";
import FlatList from 'flatlist-react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
margin-top: calc(50vh - 15rem);

`;

// FOOTER
{/* <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=4txLVcQQxbxta6COvQNaO7FvQ41EkpCN7uJqS2Rdm3XmNBnJZuzu51D6qw73"></script></span> */}
function PortfolioDesktop({ projects, theme }) {

  const [selectedProjects, setSelectedProjects] = useState(projects.all);
  const [selectedHash, setSelectedHash] = useState('all')
  const bg = 
  theme === 'light'
  ? 'project-r-light'
  : (
      theme === 'dark'
      ? 'project-r-dark'
      : ''
  )

  const tColor = 
  theme === 'light'
  ? 'dark-color'
  : (
      theme === 'dark'
      ? 'white-color'
      : ''
  )


  return (
    <div className="container-d" id="">
      <div className="head-d" style={{ height: '15rem' }}> 
        <div className="portfolio-header full-w">
          <div
            style={{
              fontSize: "3rem",
              fontWeight: "500", 
              color: "#ee7752",
              marginTop: "3rem",
            }}
          >
            Projects
          </div>
          <div id="phone-portfolio" className={`row full-w padding-l-x padding-r-x h-scroll margin-t-xx j-start a-center`} style={{height: '9rem', flexWrap: 'wrap'}}>
            <div onClick={() => setSelectedHash('all', setSelectedProjects(projects.all))} className={`option-header ${selectedHash === 'all' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'all' && 'var(--main_color)', color:selectedHash === 'all' && 'var(--white)'}}>All</div>
            <div onClick={() => setSelectedHash('web', setSelectedProjects(projects.web))} className={`option-header ${selectedHash === 'web' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'web' && 'var(--main_color)', color:selectedHash === 'web' && 'var(--white)'}}>Web</div>
            <div onClick={() => setSelectedHash('mobile', setSelectedProjects(projects.mobile))} className={`option-header ${selectedHash === 'mobile' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'mobile' && 'var(--main_color)', color:selectedHash === 'mobile' && 'var(--white)'}}>Mobile</div>
            <div onClick={() => setSelectedHash('desktop', setSelectedProjects(projects.desktop))} className={`option-header ${selectedHash === 'desktop' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'desktop' && 'var(--main_color)', color:selectedHash === 'desktop' && 'var(--white)'}}>Desktop</div>
            <div onClick={() => setSelectedHash('frontend', setSelectedProjects(projects.frontend))} className={`option-header ${selectedHash === 'frontend' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'frontend' && 'var(--main_color)', color:selectedHash === 'frontend' && 'var(--white)'}}>Frontend</div>
            <div onClick={() => setSelectedHash('backend', setSelectedProjects(projects.backend))} className={`option-header ${selectedHash === 'backend' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'backend' && 'var(--main_color)', color:selectedHash === 'backend' && 'var(--white)'}}>Backend</div>
            <div onClick={() => setSelectedHash('fullstack', setSelectedProjects(projects.fullstack))} className={`option-header ${selectedHash === 'fullstack' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'fullstack' && 'var(--main_color)', color:selectedHash === 'fullstack' && 'var(--white)'}}>Fullstack</div>
            <div onClick={() => setSelectedHash('fulltime', setSelectedProjects(projects.fulltime))} className={`option-header ${selectedHash === 'fulltime' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'fulltime' && 'var(--main_color)', color:selectedHash === 'fulltime' && 'var(--white)'}}>Fulltime</div>
            <div onClick={() => setSelectedHash('college', setSelectedProjects(projects.college))} className={`option-header ${selectedHash === 'college' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'college' && 'var(--main_color)', color:selectedHash === 'college' && 'var(--white)'}}>College</div>
            <div onClick={() => setSelectedHash('internship', setSelectedProjects(projects.internship))} className={`option-header ${selectedHash === 'internship' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'internship' && 'var(--main_color)', color:selectedHash === 'internship' && 'var(--white)'}}>Internship</div>
            <div onClick={() => setSelectedHash('hackathon', setSelectedProjects(projects.hackathon))} className={`option-header ${selectedHash === 'hackathon' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'hackathon' && 'var(--main_color)', color:selectedHash === 'hackathon' && 'var(--white)'}}>Hackathon</div>
            <div onClick={() => setSelectedHash('personal', setSelectedProjects(projects.personal))} className={`option-header ${selectedHash === 'personal' && 'selected-cat'}`} style={{backgroundColor: selectedHash === 'personal' && 'var(--main_color)', color:selectedHash === 'personal' && 'var(--white)'}}>Personal</div>
          </div>

        </div>
      </div>

      <div className="body-d col a-start" style={{ height: "100%", justifyContent:'space-between' }}>
        {selectedProjects &&
          <FlatList
            list={selectedProjects}
            renderWhenEmpty={() =>
              <div className="full-w fit-content-h col center-hv">
                <ScaleLoader
                  css={override}
                  height={'15vh'}
                  width={'3rem'}
                  color={"#f9f9f950"}
                  loading={true}
                />
                <div className="lable color-white margin-t-xx" style={{ fontSize: '1rem' }}>Loading From Server...</div>
              </div>
            }
            key={(item, index) => index}
            renderOnScroll
            renderItem={(item, index) => {
              return (
                <div className="projects-holder">

                  <FlatList
                    list={item}
                    renderWhenEmpty={() =>
                      <div className="full-w fit-content-h col center-hv">
                        <ScaleLoader
                          css={override}
                          height={'15vh'}
                          width={'3rem'}
                          color={"#f9f9f950"}
                          loading={true}
                        />
                        <div className="lable color-white margin-t-xx" style={{ fontSize: '1rem' }}>Loading From Server...</div>
                      </div>
                    }

                    key={(project, project_index) => project_index}
                    renderOnScroll
                    renderItem={(project, project_index) => {
                      return (
                        <div className="projects">
                          {project_index === 0 ?
                            (
                              <div className="project-base" style={{marginRight:'1vw'}}>
                                <div className={`project-l ${bg}`}>
                                  <div className="project-header col" style={{ borderTopLeftRadius: "1rem" }}>
                                    <div style={{fontSize:'1.2vw'}}>{project.project_name}</div>
                                    <div className="lable color-secondary" style={{ fontSize: '1rem', color:'#f9f9f9cc' }}>{`${project.org} - ${project.work_type}`}</div>
                                  </div>

                                  <div className={`project-body ${tColor}`}>
                                    {project.desc}
                                  </div>
                                  <div className="project-technologies full-w j-end">

                                    <FlatList
                                      list={project.tech_stack}
                                      key={(tech, tech_index) => tech.icon}
                                      renderOnScroll
                                      renderItem={(tech) => {
                                        return (
                                          <div className="fit-content-w fit-content-h col center-hv">
                                            <img
                                              src={tech.icon}
                                              alt={"non"}
                                              className="portfolio-tech-icon"
                                            />
                                          </div>
                                        );
                                      }} />

                                  </div>
                                </div>
                              </div>
                            )
                            :
                            (
                              <div className="project-base" style={{marginLeft:'1vw'}}>
                                <div className={`project-r ${bg}`}>
                                  <div className="project-header col" style={{ borderTopRightRadius: "1rem" }}>
                                    <div style={{fontSize:'1.2vw'}}>{project.project_name}</div>
                                    <div className="lable " style={{ fontSize: '1rem', color:'#f9f9f9cc' }}>{`${project.org} - ${project.work_type}`}</div>
                                  </div>
                                  <div className={`project-body ${tColor}`}>
                                    {project.desc}
                                  </div>
                                  <div className="project-technologies">
                                    <FlatList
                                      list={project.tech_stack}
                                      renderWhenEmpty={() => <div>List is empty!</div>}
                                      key={(tech, tech_index) => tech.icon}
                                      renderOnScroll
                                      renderItem={(tech) => {
                                        return (
                                          <div className="fit-content-w fit-content-h col center-hv">
                                            <img
                                              src={tech.icon}
                                              alt={"non"}
                                              className="portfolio-tech-icon"
                                            />
                                          </div>
                                        );
                                      }} />
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      );
                    }}
                  />
                </div>
              );
            }} />

            
        }

            <div className="footer-d" style={{ height: '5rem' }}>
                <div className={`copy-right ${tColor}`}>
                Copyrights &#169; belongs to author (
                <a
                    href="https://github.com/ElBashah"
                    target="_blank"
                    style={{ color: "#ee7752", fontWeight: "800" }}
                >
                    Elbashah
                </a>
                )
                </div>
            </div>
      </div>

      
    </div>
  );
}

export default PortfolioDesktop;
