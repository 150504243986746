import React, {useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import HOME from './home-desktop'
import RESUME from './resume-desktop';
import BOT from './bot-desktop';
import Recommendation from "./recommendation";
import { getTestimonials } from '../js/get';
// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
function MainDesktop ({visitorForm, setVisitorForm}) {
  const [recVisible, setRecVisible] = useState(false);
  const [testimonials, setTestimonials] = useState([]);

  const selectedTheme = localStorage.getItem('theme')
  const [theme, setTheme] = useState(selectedTheme || 'main')

  useEffect(() => {
    
    const getInfo = async () => {
      const newTestimonials = await getTestimonials();
    setTestimonials(newTestimonials, console.log("\n\n[TT]:: ", newTestimonials));
    }
    getInfo()
  }, []);

  return(
    <main> 
  
    <HOME setRecVisible={setRecVisible} testimonials={testimonials} theme={theme} setTheme={setTheme} visitorForm={visitorForm} setVisitorForm={setVisitorForm} />
    {recVisible && <Recommendation setRecVisible={setRecVisible} theme={theme} />}
  </main>
  );
}

export default MainDesktop

  // {/* <Switch>
  //     <Route exact path='/' render={(props) => <HOME setRecVisible={setRecVisible} testimonials={testimonials}/>} />
  //     <Route path='/bash-bot' component={BOT}/>
  //     <Route path='/resume' component={RESUME}/>
  //   </Switch> */}