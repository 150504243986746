
import { DeviceUUID } from "device-uuid";



export const dark = 'var(--dark)'
export const white = 'var(--white)'
export const main = 'var(--main_color)'
export const dimDark = 'var(--dim_dark)'
export const extreemDark = 'var(--extreem_dark)'
export const purple = 'var(--background)'
export const light = 'var(--light)'

export const isEmpty = txt => {
    if (txt === "" || txt === null || txt === " " || txt === undefined || txt === "undefined") {
        return true;
    }
    return false;
}

export const getDeviceUUID = () => new DeviceUUID().get();

export const reArrangeProjects = (projects) => {

    let web_projects = [];
    let mobile_projects = [];
    let desktop_projects = [];
    let frontent_projects = [];
    let backend_projects = [];
    let fullstack_projects = [];
    let fulltime_projects = [];
    let college_projects = [];
    let internship_projects = [];
    let hackathon_projects = [];
    let personal_projects = [];
    let all = [];

    let t_web_projects = [];
    let t_mobile_projects = [];
    let t_desktop_projects = [];
    let t_frontent_projects = [];
    let t_backend_projects = [];
    let t_fullstack_projects = [];
    let t_fulltime_projects = [];
    let t_college_projects = [];
    let t_internship_projects = [];
    let t_hackathon_projects = [];
    let t_personal_projects = [];
    let t_all = [];

    projects.forEach((doc) => {

        if (doc.project_type.toLowerCase() === "desktop")
          t_desktop_projects.push(doc);

        if (doc.project_type.toLowerCase() === "mobile")
          t_mobile_projects.push(doc);

        if (doc.project_type.toLowerCase() === "web")
          t_web_projects.push(doc);

        if (doc.work_type.toLowerCase() === "college")
          t_college_projects.push(doc);

        if (doc.work_type.toLowerCase() === "full-time job")
          t_fulltime_projects.push(doc);

        if (doc.work_type.toLowerCase() === "internship")
          t_internship_projects.push(doc);

        if (doc.work_type.toLowerCase() === "personal")
          t_personal_projects.push(doc);

          if (doc.work_type.toLowerCase() === "hackathon")
          t_hackathon_projects.push(doc);

        if (
          doc.role.toLowerCase() !== "front-end" &&
          doc.role.toLowerCase() !== "back-end"
        )
          t_fullstack_projects.push(doc);

        if (doc.role.toLowerCase() === "front-end")
          t_frontent_projects.push(doc);

        if (doc.role.toLowerCase() === "back-end")
          t_backend_projects.push(doc);

        if (doc.project_type.toLowerCase() === "cross platform") {
          t_desktop_projects.push(doc);
          t_mobile_projects.push(doc);
          t_web_projects.push(doc);
        }

        t_all.push(doc);

        if (t_all.length > 1) {
          all.push(t_all);
          t_all = [];
        }

        if (t_backend_projects.length === 2) {
          backend_projects.push(t_backend_projects);
          t_backend_projects = [];
        }

        if (t_frontent_projects.length === 2) {
          frontent_projects.push(t_frontent_projects);
          t_frontent_projects = [];
        }

        if (t_fullstack_projects.length === 2) {
          fullstack_projects.push(t_fullstack_projects);
          t_fullstack_projects = [];
        }

        if (t_fulltime_projects.length === 2) {
          fulltime_projects.push(t_fulltime_projects);
          t_fulltime_projects = [];
        }

        if (t_college_projects.length === 2) {
          college_projects.push(t_college_projects);
          t_college_projects = [];
        }

        if (t_desktop_projects.length === 2) {
          desktop_projects.push(t_desktop_projects);
          t_desktop_projects = [];
        }

        if (t_mobile_projects.length === 2) {
          mobile_projects.push(t_mobile_projects);
          t_mobile_projects = [];
        }

        if (t_web_projects.length === 2) {
          web_projects.push(t_web_projects);
          t_web_projects = [];
        }

        if (t_hackathon_projects.length === 2) {
          hackathon_projects.push(t_hackathon_projects);
          t_hackathon_projects = [];
        }

        if (t_personal_projects.length === 2) {
          personal_projects.push(t_personal_projects);
          t_personal_projects = [];
        }

        if (t_internship_projects.length === 2) {
          internship_projects.push(t_internship_projects);
          t_internship_projects = [];
        }

    });

  return {
    web: web_projects,
    mobile: mobile_projects,
    desktop: desktop_projects,
    frontend: frontent_projects,
    backend: backend_projects,
    fullstack: fullstack_projects,
    fulltime: fulltime_projects,
    college: college_projects,
    internship: internship_projects,
    hackathon: hackathon_projects,
    personal: personal_projects,
    all: all,
  }; 
}


export const reArrangeProjectsPhone = (projects) => {

    if(!projects) projects = []

    let web_projects = [];
    let mobile_projects = [];
    let desktop_projects = [];
    let frontent_projects = [];
    let backend_projects = [];
    let fullstack_projects = [];
    let fulltime_projects = [];
    let college_projects = [];
    let internship_projects = [];
    let hackathon_projects = [];
    let personal_projects = [];
    let all = [];


    projects.forEach((doc) => {

        if (doc.project_type.toLowerCase() === "desktop")
          desktop_projects.push(doc);

        if (doc.project_type.toLowerCase() === "mobile")
          mobile_projects.push(doc);

        if (doc.project_type.toLowerCase() === "web")
          web_projects.push(doc);

        if (doc.work_type.toLowerCase() === "college")
          college_projects.push(doc);

        if (doc.work_type.toLowerCase() === "full-time job")
          fulltime_projects.push(doc);

        if (doc.work_type.toLowerCase() === "internship")
          internship_projects.push(doc);

        if (doc.work_type.toLowerCase() === "personal")
          personal_projects.push(doc);

          if (doc.work_type.toLowerCase() === "hackathon")
          hackathon_projects.push(doc);

        if (
          doc.role.toLowerCase() !== "front-end" &&
          doc.role.toLowerCase() !== "back-end"
        )
          fullstack_projects.push(doc);

        if (doc.role.toLowerCase() === "front-end")
          frontent_projects.push(doc);

        if (doc.role.toLowerCase() === "back-end")
          backend_projects.push(doc);

        if (doc.project_type.toLowerCase() === "cross platform") {
          desktop_projects.push(doc);
          mobile_projects.push(doc);
          web_projects.push(doc);
        }

        all.push(doc);

    });

  return {
    web: web_projects,
    mobile: mobile_projects,
    desktop: desktop_projects,
    frontend: frontent_projects,
    backend: backend_projects,
    fullstack: fullstack_projects,
    fulltime: fulltime_projects,
    college: college_projects,
    internship: internship_projects,
    hackathon: hackathon_projects,
    personal: personal_projects,
    all: all,
  }; 
}