import React from 'react';
// import '../App.css';
import {Link} from "react-router-dom";
import './desktop.css';
// import  GoPerson from 'react-icons/go/index';
import { GoPerson } from 'react-icons/go';
import { BsFileEarmarkText } from 'react-icons/bs';
import { FaCode, FaGithubAlt, FaInstagram, FaPhoneAlt } from 'react-icons/fa';
import { MdEmail, } from 'react-icons/md';
import {TiSocialLinkedin} from 'react-icons/ti'
import { APP } from "../js/app-constants";
import { dark, dimDark, main, white } from '../js/util';

function SideMenuDesktop({setSelectedPage, theme, setTheme}) {
    const background = theme === 'main'? '--background' : (theme === 'dark'? '--dark' : '--light')

    return (
        <div className="side-menu-continer" style={{ display: 'flex', backgroundColor: `var(${background})`}}>
            <div className="side-menu-holder" style={{}}>
                <div className="bash-image" style={{ justifyContent: 'center', display: 'flex' }}>
                    <img src={require('../images/portfolio_pic.jpg')} alt={'non'} style={{
                        width: '10rem',
                        height: '10rem', borderRadius: '100rem', border: `0.15rem ${theme === 'light'? dark : (theme === 'dark'? main : 'rgb(238, 0, 118)')} solid`, marginTop: '2rem',
                    }} />
                </div>

                <div className="bash-info" style={{}}>
                    <div style={{ fontSize: '1.4rem', width: '80%', textAlign: 'center', fontWeight: '600', color: theme === 'light'? dark : '' }}>MOHAMMED ALBASHA</div>
                    <div className={`full-w margin-t-x font-lable-m white-color padding-l-x padding-r-x t-center`}
                            style={{lineHeight: 1.3, height: '3rem', fontWeight: '300', fontWeight: 400, color: theme === 'light'? dark : ''}}>
                            Founder & Lead Development Engineer @ <a target="_blank" href="https://www.pass-me.com">Pass-Me.com</a> 
                    </div>
                </div>

                <div style={{display:'flex', justifyContent:'center'}}>
                <div className="themes-holder" style={{width: '70%'}}>
                    <div className="social-icon" style={{cursor:'pointer', color: theme !== 'main'? white : ''}} onClick={() => window.open(APP.linkedin, "_blank")}>
                        <TiSocialLinkedin />
                    </div>
                    <div className="social-icon" style={{cursor:'pointer', color: theme !== 'main'? white : ''}} onClick={() => window.open(APP.github, "_blank")}>
                        <FaGithubAlt />
                    </div>
                    <div className="social-icon" style={{cursor:'pointer', color: theme !== 'main'? white : ''}} onClick={() => window.open(APP.instagram, "_blank")}>
                        <FaInstagram />
                    </div>
                </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}><div className="rounded-seperator"></div></div>

                <div className="dir-links-holds" style={{ padding: '1rem', width: '100%' }}>
                    <div className="dir-links">
                        <div className={`dir-link-${theme === 'dark'? 'dark' : (theme === 'main'? 'main' : 'light') }`} onClick={() => setSelectedPage('about')}>
                            <div className="link-icon">
                                <GoPerson />
                            </div>
                            <div className="link-text" style={{color: theme === 'light'? dark : ''}}>About me</div>
                        </div>
                        <div className={`dir-link-${theme === 'dark'? 'dark' : (theme === 'main'? 'main' : 'light') }`} onClick={() => setSelectedPage('portfolio')}>
                            <div className="link-icon">
                                <FaCode />
                            </div>
                            <div className="link-text" style={{color: theme === 'light'? dark : ''}}>Portfolio</div>
                        </div>
                        <div className={`dir-link-${theme === 'dark'? 'dark' : (theme === 'main'? 'main' : 'light') }`} onClick={() => setSelectedPage('resume')}>
                            <div className="link-icon">
                                <BsFileEarmarkText />
                            </div>
                            <div className="link-text" style={{color: theme === 'light'? dark : ''}}>Resume</div>
                        </div>

                        {/* <Link to="bash-bot">
                        <div className={`dir-link-${theme === 'dark'? 'dark' : (theme === 'main'? 'main' : 'light') }`} onClick={() => {}}>
                            <div className="link-icon">
                                <FaRobot />
                            </div>
                            <div className="link-text">Bash AI Bot</div>
                        </div>
                        </Link> */}
                       
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', }}><div className="rounded-seperator"></div></div>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}><div className="link-text" style={{ marginLeft: '0rem', color: theme === 'light'? dark : '' }}>Themes</div></div>

                <br/>
                <div className={`row full-w j-around`}>
                    <div className={`row theme-holder`} style={{maxWidth: '13rem'}}>
                        <div onClick={() => setTheme('dark', localStorage.setItem('theme', 'dark'))} style={{cursor: 'pointer', backgroundColor: theme === 'dark'? dimDark : '', width: 'calc(100% / 3)', height: '100%', borderTopLeftRadius: '5rem', borderBottomLeftRadius: '5rem', padding: '0.5rem' }} className="row center-hv"> <div className={``} style={{}}><img src={require('../assets/moon.png')} alt={'non'} style={{ width: '1.7rem', cursor: 'pointer', height: '1.7rem',  }} /></div>  </div> 
                        <div onClick={() => setTheme('main', localStorage.setItem('theme', 'main'))} style={{cursor: 'pointer', backgroundColor: theme === 'main'? dark : '', width: 'calc(100% / 3)', height: '100%', padding: '0.5rem' }} className="row center-hv "> <div className={`gradiant`} style={{ width: '1.7rem', height: '1.7rem', borderRadius: '5rem' }}>&nbsp;</div>   </div>
                        <div onClick={() => setTheme('light', localStorage.setItem('theme', 'light'))} style={{cursor: 'pointer', backgroundColor: theme === 'light'? dark : '', width: 'calc(100% / 3)', height: '100%', borderTopRightRadius: '5rem', borderBottomRightRadius: '5rem', padding: '0.5rem' }} className="row center-hv"> <div className={``} style={{}}><img src={require('../assets/sun.png')} alt={'non'} style={{ width: '1.7rem', height: '1.7rem',  }} /></div>  </div> 
                    </div>
                </div>

                {/* <br/> */}
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}><div className="rounded-seperator"></div></div>
                <br/>

                <div className="dir-links-holds" style={{ padding: '1rem', width: '100%' }}>
                    <div className="dir-links">
                        <div className={`dir-link-${theme === 'dark'? 'dark' : (theme === 'main'? 'main' : 'light') }`} style={{height: '1.7rem'}} >
                            <div className="link-icon">
                                <FaPhoneAlt style={{ width: '1.5rem', height: '1.5rem', color: '#ee7752' }}/>
                            </div>
                            <div className={`link-text `} style={{fontSize: '0.85rem', color: theme === 'light'? dark : ''}}>+1-480-401-7023</div>
                        </div>
                        {/* <br/> */}
                        <div className={`margin-t-x dir-link-${theme === 'dark'? 'dark' : (theme === 'main'? 'main' : 'light') }`} style={{height: '1.7rem'}} >
                            <div className="link-icon">
                            <MdEmail style={{ width: '1.8rem', height: '1.8rem', color: '#ee7752' }}/>
                            </div>
                            <div className={`link-text `} style={{fontSize: '0.85rem', textDecoration: 'underline', color: theme === 'light'? dark : ''}}>mohammed.a.albasha@gmail.com</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SideMenuDesktop;