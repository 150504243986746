import { auth } from 'firebase';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import './App.css';
import MainDesktop from './desktop/main-desktop';
import { postAnonynousVisitor } from './js/post';
// import { signAnonymousUser } from './js/firebase';
import MainPhone from './phone/phone';

function App() {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 450px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  const [visitorForm, setVisitorForm] = useState('')
  useEffect(() => {
    
    const signInUser = async () => {
      const res = await auth().signInAnonymously()
      const user = {
        uid: res.user.uid, 
        web_engine: navigator.userAgent, 
        platform_type: navigator.platform, 
        browser: `${navigator.appCodeName}-${navigator.vendor}`, 
        browser_language: window.navigator.language, 
        location: window.navigator.geolocation || {}, 
      }
      const visitor = await postAnonynousVisitor(user)
        if(visitor.name) setVisitorForm(visitor.name)
        else setVisitorForm('false')
    } 
    
    signInUser()
  }, [])
  return (
    <>
      <div id="">
          {isDesktopOrLaptop? <MainDesktop visitorForm={visitorForm} setVisitorForm={setVisitorForm} />
          : <MainPhone visitorForm={visitorForm} setVisitorForm={setVisitorForm} /> }
      </div>
    </>
  );
}

export default App;
